import React from 'react'
import { useParams } from 'react-router'

import { useQuery } from '@apollo/client'
import { Stack } from '@mui/material'

import { Browser, TitleHeader } from 'Components/Blocks'

import {
  DatoCmsInsightDocument,
  InsightRecordFragment,
} from 'GraphQL/DatoCMS/TypedDocuments'

import { InsightType } from 'Interfaces/Enums'

type RouteParams = {
  insightId?: InsightRecordFragment['id']
}

function InsightBrowserPage() {
  const { insightId } = useParams<RouteParams>()

  const { data } = useQuery(DatoCmsInsightDocument, {
    variables: { filter: { id: { eq: insightId } } },
    skip: !insightId,
  })
  const insight = data?.insight

  if (insight?.insightType !== InsightType.Masterclass) {
    return null
  }

  const url = insight.masterclassJoinButtonUrl ?? '#'

  return (
    <Stack flexGrow={1} overflow="hidden">
      <TitleHeader title={insight?.title ?? ''} />
      {!!url && <Browser src={url} title="Masterclass" />}
    </Stack>
  )
}

export default InsightBrowserPage
