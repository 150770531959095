import React, { useCallback, useMemo, useState } from 'react'
import { ItemContent, Virtuoso } from 'react-virtuoso'

import { useQuery } from '@apollo/client'
import { Refresh, Search } from '@mui/icons-material'
import {
  Chip,
  CircularProgress,
  Divider,
  Fab,
  IconButton,
  Stack,
} from '@mui/material'
import { useDebounce } from 'use-debounce'

import { InsightItem, MainHeader, SearchHeader } from 'Components/Blocks'

import {
  DatoCmsAllInsightsDocument,
  DatoCmsAllInsightsQueryVariables,
  InsightModelOrderBy,
  InsightRecordFragment,
} from 'GraphQL/DatoCMS/TypedDocuments'

import { InsightType } from 'Interfaces/Enums'

type TypeFilter = InsightType | null

function InsightsPage() {
  const [searchEnabled, setSearchEnabled] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [typeFilter, setTypeFilter] = useState<TypeFilter>(null)
  const [debouncedSearchTerm] = useDebounce(searchTerm, 700)
  const [refreshing, setRefreshing] = useState(false)

  const allInsightsFilter = useMemo(() => {
    const result: DatoCmsAllInsightsQueryVariables['filter'] = {}

    if (debouncedSearchTerm) {
      result.title = {
        matches: {
          pattern: debouncedSearchTerm,
        },
      }
    }

    if (typeFilter) {
      result.insightType = { eq: typeFilter }
    }

    return result
  }, [typeFilter, debouncedSearchTerm])

  const { data, fetchMore, refetch } = useQuery(DatoCmsAllInsightsDocument, {
    variables: {
      filter: allInsightsFilter,
      orderBy: InsightModelOrderBy.CreatedAtDesc,
      first: 10,
    },
  })

  const insights = useMemo(() => data?.allInsights ?? [], [data])

  const enableSearch = useCallback(() => {
    setSearchEnabled(true)
  }, [])

  const disableSearch = useCallback(() => {
    setSearchEnabled(false)
    setSearchTerm('')
  }, [])

  const loadMore = useCallback(async () => {
    if (!insights.length) return

    await fetchMore({
      variables: {
        skip: insights.length,
      },
    })
  }, [insights, fetchMore])

  const handleRefresh = useCallback(async () => {
    setRefreshing(true)
    await refetch()
    setRefreshing(false)
  }, [refetch])

  const renderItemContent = useCallback<
    ItemContent<InsightRecordFragment, any>
  >(
    (index, item) => (
      <Stack>
        {index > 0 && <Divider sx={{ mx: 2 }} />}
        <InsightItem item={item} p={2} />
      </Stack>
    ),
    [],
  )

  const mainHeaderBottom = useMemo(() => {
    const renderChip = (filter: TypeFilter, label: string) => (
      <Chip
        clickable
        color={filter === typeFilter ? 'primary' : 'default'}
        label={label}
        onClick={() => setTypeFilter(filter)}
      />
    )

    return (
      <Stack direction="row" spacing={1}>
        {renderChip(null, 'All')}
        {renderChip(InsightType.Article, 'Article')}
        {renderChip(InsightType.Podcast, 'Podcast')}
        {renderChip(InsightType.Masterclass, 'Masterclass')}
      </Stack>
    )
  }, [typeFilter])

  return (
    <Stack flexGrow={1} position="relative">
      {searchEnabled ? (
        <SearchHeader
          searchTerm={searchTerm}
          onClose={disableSearch}
          onSearchTermChange={setSearchTerm}
        />
      ) : (
        <MainHeader
          bottom={mainHeaderBottom}
          description="The Digital Speaker original content"
          noBackground
          right={
            <IconButton onClick={enableSearch}>
              <Search />
            </IconButton>
          }
          title="Insights"
        />
      )}

      <Virtuoso
        data={insights}
        endReached={loadMore}
        itemContent={renderItemContent}
      />

      <Fab
        sx={theme => ({
          position: 'absolute',
          bottom: theme.spacing(2),
          right: theme.spacing(2),
        })}
        onClick={handleRefresh}
      >
        {refreshing ? (
          <CircularProgress color="inherit" size={24} />
        ) : (
          <Refresh color="inherit" />
        )}
      </Fab>
    </Stack>
  )
}

export default InsightsPage
