import React, { Suspense, useCallback } from 'react'
import { renderRoutes, RouteConfig } from 'react-router-config'
import { NavLink } from 'react-router-dom'

import ArticleIcon from '@mui/icons-material/Article'
import InsightsIcon from '@mui/icons-material/Insights'
import LogoutIcon from '@mui/icons-material/Logout'
import PeopleIcon from '@mui/icons-material/People'
import {
  Box,
  Button,
  CircularProgress,
  Drawer,
  List,
  ListItemIcon,
  ListItemText,
  Stack,
} from '@mui/material'

import * as paths from 'Constants/paths'

import Auth from 'Services/Auth'

import { StyledListItem } from './styles'

type Props = {
  route?: RouteConfig
}

function AdminLayout({ route }: Props) {
  const handleLogoutClock = useCallback(() => {
    Auth.logOut().then()
  }, [])

  return (
    <Stack direction="row" spacing={2} sx={{ height: '100vh' }}>
      <Drawer sx={{ width: 150 }} variant="permanent">
        <List
          sx={{
            width: 150,
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          <StyledListItem
            activeClassName="active"
            component={NavLink}
            to={paths.ADMIN_USERS}
          >
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary="Users" />
          </StyledListItem>

          <StyledListItem
            activeClassName="active"
            component={NavLink}
            to={paths.ADMIN_ARTICLES}
          >
            <ListItemIcon>
              <ArticleIcon />
            </ListItemIcon>
            <ListItemText primary="Articles" />
          </StyledListItem>

          <StyledListItem
            activeClassName="active"
            component={NavLink}
            to={paths.ADMIN_INSIGHTS}
          >
            <ListItemIcon>
              <InsightsIcon />
            </ListItemIcon>
            <ListItemText primary="Insights" />
          </StyledListItem>

          <Button
            startIcon={<LogoutIcon />}
            sx={{ mt: 'auto', textTransform: 'none' }}
            variant="text"
            onClick={handleLogoutClock}
          >
            <ListItemText primary="Log out" />
          </Button>
        </List>
      </Drawer>

      <Stack
        direction="column"
        justifyContent="flex-start"
        sx={{ flex: 1, overflowY: 'auto' }}
      >
        <Suspense
          fallback={
            <Box
              alignItems="center"
              display="flex"
              flexDirection="column"
              flexGrow={1}
              justifyContent="center"
              p={2}
            >
              <CircularProgress />
            </Box>
          }
        >
          {renderRoutes(route?.routes)}
        </Suspense>
      </Stack>
    </Stack>
  )
}

export default AdminLayout
