import React, { useMemo } from 'react'
import ReactMarkdown from 'react-markdown'

import truncateMarkdown from 'markdown-truncate'
import remarkBreaks from 'remark-breaks'

import * as MarkdownComponents from './components'

type Props = {
  children?: string | null
  truncate?: boolean
  truncateLimit?: number
}

function Markdown({ children, truncate, truncateLimit }: Props) {
  const content = useMemo(() => {
    let value = children ?? ''
    value = value.replace(/\n/gi, '&nbsp; \n')

    if (truncate && truncateLimit) {
      value = truncateMarkdown(value, { limit: truncateLimit, ellipsis: true })
    }

    return value
  }, [children, truncate, truncateLimit])

  return (
    <ReactMarkdown
      components={{
        code: MarkdownComponents.Code,
        p: MarkdownComponents.P,
        strong: MarkdownComponents.Strong,
      }}
      remarkPlugins={[remarkBreaks]}
    >
      {content}
    </ReactMarkdown>
  )
}

export { MarkdownComponents }

export default Markdown
