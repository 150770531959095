import { useCallback, useMemo, useRef } from 'react'

import { NetworkStatus, useLazyQuery, useQuery } from '@apollo/client'

import groupBy from 'lodash/groupBy'
import map from 'lodash/map'

import { BOOKMARK_LIST_LIMIT } from 'Constants/queriesVariables'

import {
  ArticleRecord,
  DatoCmsAllArticlesDocument,
  DatoCmsAllInsightsDocument,
  InsightRecord,
} from 'GraphQL/DatoCMS/TypedDocuments'
import {
  BookmarksDocument,
  DatoModelKind,
  UserBookmark,
} from 'GraphQL/Main/TypedDocuments'

type BookmarkItem = {
  id: UserBookmark['id']
} & (
  | {
      kind: DatoModelKind.Article
      data: ArticleRecord
    }
  | {
      kind: DatoModelKind.Insight
      data: InsightRecord
    }
)

function useBookmarks() {
  const bookmarksPage = useRef(0)

  const [loadArticles, { data: articlesData, refetch: articlesRefetch }] =
    useLazyQuery(DatoCmsAllArticlesDocument)
  const [loadInsights, { data: insightsData, refetch: insightsRefetch }] =
    useLazyQuery(DatoCmsAllInsightsDocument)
  const {
    data: bookmarksData,
    fetchMore: bookmarksFetchMore,
    refetch: bookmarksRefetch,
    networkStatus: bookmarksNetworkStatus,
  } = useQuery(BookmarksDocument, {
    variables: { limit: BOOKMARK_LIST_LIMIT },
    notifyOnNetworkStatusChange: true,

    onCompleted: async data => {
      const bookmarks = data.bookmarks.rows ?? []
      const bookmarksByKind = groupBy(bookmarks, 'kind')
      const articleBookmarks = bookmarksByKind[DatoModelKind.Article] ?? []
      const insightBookmarks = bookmarksByKind[DatoModelKind.Insight] ?? []

      if (articleBookmarks.length > 0) {
        await loadArticles({
          variables: {
            filter: { id: { in: map(articleBookmarks, 'entityId') } },
          },
        })
      }
      if (insightBookmarks.length > 0) {
        await loadInsights({
          variables: {
            filter: { id: { in: map(insightBookmarks, 'entityId') } },
          },
        })
      }
    },
  })

  const bookmarks = useMemo(() => {
    const list = bookmarksData?.bookmarks.rows ?? []
    const articleById = (articlesData?.allArticles ?? []).reduce(
      (acc, item) => ({ ...acc, [item.id]: item }),
      {},
    )
    const insightById = (insightsData?.allInsights ?? []).reduce(
      (acc, item) => ({ ...acc, [item.id]: item }),
      {},
    )

    return list.reduce((acc, item) => {
      let result: BookmarkItem | undefined

      if (item.kind === DatoModelKind.Article) {
        const data = articleById[item.entityId]

        if (data) {
          result = { data, id: item.id, kind: item.kind }
        }
      } else if (item.kind === DatoModelKind.Insight) {
        const data = insightById[item.entityId]

        if (data) {
          result = { data, id: item.id, kind: item.kind }
        }
      }

      return result ? acc.concat(result) : acc
    }, [] as BookmarkItem[])
  }, [articlesData, insightsData, bookmarksData])

  const refetch = useCallback(() => {
    return Promise.all([
      articlesRefetch(),
      insightsRefetch(),
      bookmarksRefetch(),
    ])
  }, [articlesRefetch, insightsRefetch, bookmarksRefetch])

  const loadMore = useCallback(async () => {
    if (
      !bookmarksData ||
      bookmarksData.bookmarks.count === bookmarksData.bookmarks.rows.length ||
      bookmarksNetworkStatus === NetworkStatus.loading ||
      bookmarksNetworkStatus === NetworkStatus.fetchMore
    ) {
      return
    }

    bookmarksPage.current += 1

    await bookmarksFetchMore({
      variables: {
        page: bookmarksPage.current,
      },
    })
  }, [bookmarksData, bookmarksNetworkStatus, bookmarksFetchMore])

  return { bookmarks, refetch, loadMore }
}

export type { BookmarkItem }
export { useBookmarks }
