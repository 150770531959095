import merge from 'lodash/merge'

import DefaultTheme from 'Theme/Default'

import MuiTheme from './MuiTheme'

export default merge({}, DefaultTheme, {
  name: 'Dark Theme',
  dark: true,

  MuiTheme,
})
