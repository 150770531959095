import { Operation } from '@apollo/client'
import { getMainDefinition } from '@apollo/client/utilities'

function isBatchOperation({ getContext }: Operation) {
  return getContext().batch === true
}

function isDatoCMSOperation({ getContext, operationName }: Operation) {
  return getContext().datoCMS === true || operationName.startsWith('DatoCMS')
}

function isSubscriptionOperation({ query }: Operation) {
  // @ts-ignore
  const { kind, operation } = getMainDefinition(query)
  return kind === 'OperationDefinition' && operation === 'subscription'
}

function isAdminOperation({ getContext }: Operation) {
  return getContext().admin === true
}

export {
  isAdminOperation,
  isBatchOperation,
  isDatoCMSOperation,
  isSubscriptionOperation,
}
