import { styled } from '@mui/material'

import { ReactComponent as PushNotificationsSvg } from 'Assets/Svg/pushNotifications.svg'

const PushNotificationsImage = styled(PushNotificationsSvg)(({ theme }) => ({
  '& .PushNotificationsImage-text': {
    fill: theme.palette.background.default,
  },

  '& .PushNotificationsImage-icon': {
    fill: theme.palette.primary.main,
  },
}))

export default PushNotificationsImage
