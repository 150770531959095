import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'
import { QueryClient } from '@tanstack/react-query'
import ms from 'ms'

import Shared from './Shared'

type Client = QueryClient

const CACHE_STALE_TIME = {
  VERY_SLOW: ms('15m'),
  SLOW: ms('5m'),
  FAST: ms('1m'),
  VERY_FAST: ms('15s'),
}

const storagePersister = createSyncStoragePersister({
  storage: window.localStorage,
})

function createQueryClient(): Client {
  const client = new QueryClient({
    defaultOptions: {
      queries: {
        cacheTime: ms('1d'),
      },
    },
  })

  Shared.setQueryClient(client)

  return client
}

export { CACHE_STALE_TIME, type Client, storagePersister }

export default createQueryClient
