import React, { DetailedHTMLProps, IframeHTMLAttributes, useMemo } from 'react'

import { PROXY_URL } from 'Config'

import { StyledIframe } from './styles'

type Props = DetailedHTMLProps<
  IframeHTMLAttributes<HTMLIFrameElement>,
  HTMLIFrameElement
> & {
  proxify?: boolean
}

function Browser({ proxify, src: srcProp, ...rest }: Props) {
  const src = useMemo(
    () => (proxify ? `${PROXY_URL}${srcProp}` : srcProp),
    [proxify, srcProp],
  )

  return <StyledIframe {...rest} src={src} />
}

export default Browser
