export const ERRORS_TEXT = {
  'auth.confirmationCodeInvalid': 'Confirmation code is invalid',
  'auth.emailAlreadyExists': 'Email already exists',
  'auth.emailNotFound': 'Email not found. Check email or create an account',
  'auth.incorrectEmailOrPassword': `User doesn't exist or password is wrong`,
  'auth.passwordTokenInvalid':
    'Password reset link expired. Please request a new link',
  'generic.accessDenied': "You don't have permission to perform this action",
  'generic.validation': "Password doesn't match requirements",
}
