import { UserFragment } from 'GraphQL/Main/TypedDocuments'

import StringUtils from './string'

function formatFullName(user?: Partial<UserFragment>, fallback = 'No name') {
  const firstName = user?.profile?.firstName || ''
  const lastName = user?.profile?.lastName || ''

  if (firstName || lastName) {
    return StringUtils.sanitize(`${firstName} ${lastName}`)
  }

  return user && 'email' in user && user.email ? String(user.email) : fallback
}

export default {
  formatFullName,
}
