import { PRICE_REGEXP } from 'Constants/regexps'

import { SubscriptionPlanRecordFragment } from 'GraphQL/DatoCMS/TypedDocuments'

function isFree(plan: SubscriptionPlanRecordFragment) {
  return plan.kind === 'free'
}

function isCurrentPlan(
  plan: SubscriptionPlanRecordFragment,
  currentPlan?: SubscriptionPlanRecordFragment,
) {
  return currentPlan ? currentPlan.id === plan.id : isFree(plan)
}

function isDowngrade(
  plan: SubscriptionPlanRecordFragment,
  currentPlan?: SubscriptionPlanRecordFragment,
) {
  const isCurPlan = isCurrentPlan(plan, currentPlan)
  const planPrice = plan.title?.match(PRICE_REGEXP)?.[0] ?? 0
  const currentPlanPrice = currentPlan?.title?.match(PRICE_REGEXP)?.[0] ?? 0

  return isCurPlan ? false : planPrice < currentPlanPrice
}

export default {
  isCurrentPlan,
  isDowngrade,
  isFree,
}
