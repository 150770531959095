import React, { useCallback, useState } from 'react'
import OneSignal from 'react-onesignal'
import { useHistory } from 'react-router'

import { useMutation } from '@apollo/client'
import { Button, Stack, Typography } from '@mui/material'
import { enqueueSnackbar } from 'notistack'
import Utils from 'Utils'

import { AuthHeader, PushNotificationsImage } from 'Components/Blocks'

import { ONE_SIGNAL } from 'Config'

import * as paths from 'Constants/paths'

import { SetPushNotificationsDocument } from 'GraphQL/Main/TypedDocuments'

import { useAppContext } from 'Services/AppContext'
import { SafeAreaView } from 'Services/SafeArea'
import { useApp } from 'Services/Store/app'

function NotificationsPage() {
  const [oneSignalInitialized, setOneSignalInitialized] = useState(false)

  const [setPushNotifications] = useMutation(SetPushNotificationsDocument)

  const history = useHistory()
  const { viewer } = useAppContext()
  const { setNotificationsRequested } = useApp()

  const handleSkipClick = useCallback(() => {
    setNotificationsRequested(true)
    history.replace(paths.ROOT)
  }, [history, setNotificationsRequested])

  const handleRequestClick = useCallback(async () => {
    if (!viewer) {
      history.replace(paths.ROOT)
      return
    }

    if (!oneSignalInitialized) {
      setOneSignalInitialized(true)

      await OneSignal.init({
        appId: ONE_SIGNAL.APP_ID,
        safari_web_id: ONE_SIGNAL.SAFARI_WEB_ID,
        allowLocalhostAsSecureOrigin: true,
      })

      await OneSignal.login(viewer.id)
    }

    await OneSignal.Notifications.requestPermission()
    await OneSignal.User.PushSubscription.optIn()

    try {
      await setPushNotifications({
        variables: { enable: !!OneSignal.User.PushSubscription },
      })
    } catch (error) {
      const [graphQLError] = Utils.Errors.getGraphQLErrors(error)
      enqueueSnackbar(graphQLError, { variant: 'error' })
    }

    setNotificationsRequested(true)
    history.replace(paths.ROOT)
  }, [
    viewer,
    oneSignalInitialized,
    history,
    setPushNotifications,
    setNotificationsRequested,
  ])

  return (
    <Stack flexGrow={1}>
      <AuthHeader canGoBack={false} />

      <Stack flexGrow={1} pb={2} px={4}>
        <Stack flexGrow={1}>
          <Typography align="center" variant="h5">
            Turn on notifications
          </Typography>

          <Typography align="center" color="text.secondary" mt={1.5}>
            Allow us to send you notifications when Dr Mark van Rijmenam
            recommends a new insight post
          </Typography>

          <Stack alignItems="center" mt={4}>
            <PushNotificationsImage height={undefined} width="100%" />
          </Stack>

          <Button
            size="large"
            sx={{ mt: 6 }}
            type="submit"
            variant="contained"
            onClick={handleRequestClick}
          >
            Allow notifications
          </Button>
        </Stack>

        <Button sx={{ mt: 2 }} variant="text" onClick={handleSkipClick}>
          Skip
        </Button>
      </Stack>

      <SafeAreaView bottom />
    </Stack>
  )
}

export default NotificationsPage
