import { useEffect } from 'react'
import { useLocation } from 'react-router'

import Plausible from 'plausible-tracker'

const plausible = Plausible()

function LocationTracker() {
  const { pathname } = useLocation()

  useEffect(() => {
    plausible.trackPageview({
      url: window.location.href,
    })
  }, [pathname])

  return null
}

export default LocationTracker
