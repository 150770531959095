import { persistState } from '@storeon/localstorage'
import { createStoreon, StoreonStore } from 'storeon'

import { STORE } from 'Config'

import Shared from 'Services/Shared'

import {
  Events as AppEvents,
  KEY as APP_KEY,
  module as appModule,
  State as AppState,
} from './app'
import {
  Events as SessionEvents,
  INITIAL_STATE as SESSION_INITIAL_STATE,
  KEY as SESSION_KEY,
  module as sessionModule,
  State as SessionState,
} from './session'
import {
  Events as UIEvents,
  KEY as UI_KEY,
  module as uiModule,
  State as UIState,
} from './ui'

export const STORE_CLEAR = 'clear'

interface MainEvents {
  clear: void
}

type State = AppState & SessionState & UIState
type Events = AppEvents & MainEvents & SessionEvents & UIEvents

type Store = StoreonStore<State, Events>

function createStore() {
  const modules = [
    appModule,
    sessionModule,
    uiModule,

    persistState([APP_KEY, SESSION_KEY, UI_KEY], {
      key: STORE.KEY,
    }),
  ]

  const store = createStoreon<State, Events>(modules)
  Shared.setStore(store)

  store.on(STORE_CLEAR, state => ({
    ...state,
    [SESSION_KEY]: SESSION_INITIAL_STATE,
  }))

  return store
}

export { createStore }
export type { Store }
