import React from 'react'

import { Stack } from '@mui/material'

import { SpotifyPlayer } from 'Components/Blocks'

import { InsightRecordFragment } from 'GraphQL/DatoCMS/TypedDocuments'

type Props = {
  insight: InsightRecordFragment
}

function Podcast({ insight }: Props) {
  return (
    <Stack flexGrow={1}>
      {!!insight.spotifyId && <SpotifyPlayer id={insight.spotifyId} />}
    </Stack>
  )
}

export default Podcast
