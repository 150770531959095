import React, { ChangeEventHandler, useCallback } from 'react'

import { Close, Search } from '@mui/icons-material'
import { Box, Divider, IconButton, Stack } from '@mui/material'

import { SafeAreaView } from 'Services/SafeArea'

import { SearchIconContainer, SearchInput } from './styles'

type Props = {
  searchTerm?: string
  onClose?: () => void
  onSearchTermChange?: (searchTerm: string) => void
}

function SearchHeader({ searchTerm = '', onClose, onSearchTermChange }: Props) {
  const handleInputChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    event => onSearchTermChange?.(event.target.value),
    [onSearchTermChange],
  )

  return (
    <SafeAreaView bgcolor="background.paper" top>
      <Stack direction="row" p={2}>
        <Box flexGrow={1} position="relative">
          <SearchIconContainer>
            <Search sx={{ color: 'text.secondary' }} />
          </SearchIconContainer>

          <SearchInput
            autoFocus
            placeholder="Search"
            value={searchTerm}
            onChange={handleInputChange}
          />
        </Box>

        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </Stack>

      <Divider />
    </SafeAreaView>
  )
}

export default SearchHeader
