import React, { useCallback, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { RouteComponentProps, useHistory } from 'react-router'

import { useMutation } from '@apollo/client'
import { zodResolver } from '@hookform/resolvers/zod'
import { LoadingButton } from '@mui/lab'
import { Stack, Typography } from '@mui/material'
import { MuiOtpInput } from 'mui-one-time-password-input'
import { enqueueSnackbar } from 'notistack'
import Utils from 'Utils'
import { z } from 'zod'

import { AuthHeader } from 'Components/Blocks'

import * as paths from 'Constants/paths'

import { ConfirmEmailDocument } from 'GraphQL/Main/TypedDocuments'

import { VerifyEmailPageParam } from 'Interfaces/Enums'

import Auth from 'Services/Auth'

type Props = RouteComponentProps

enum Fields {
  Code = 'code',
}

type Values = z.infer<typeof schema>

const CODE_LENGTH = 6

const schema = z.object({
  [Fields.Code]: z.string().length(CODE_LENGTH),
})

function VerifyEmailPage({ location }: Props) {
  const history = useHistory()

  const [confirmEmail] = useMutation(ConfirmEmailDocument)

  const params = useMemo(() => {
    const searchParams = new URLSearchParams(location.search)

    return {
      email: searchParams.get(VerifyEmailPageParam.Email),
    }
  }, [location])

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<Values>({
    resolver: zodResolver(schema),
    defaultValues: {
      [Fields.Code]: '',
    },
  })

  const onSubmit = useCallback(
    async (values: Values) => {
      if (!params.email) return

      try {
        const response = await confirmEmail({
          variables: {
            email: params.email,
            code: values[Fields.Code],
            withRefresh: true,
          },
        })

        await Auth.signIn({
          accessToken: response.data?.confirmEmail.accessToken,
          refreshToken: response.data?.confirmEmail.refreshToken,
        })

        enqueueSnackbar('Your email has been successfully verified', {
          variant: 'success',
        })

        history.replace(paths.REDIRECTOR)
      } catch (error) {
        const [graphQLError] = Utils.Errors.getGraphQLErrors(error)
        enqueueSnackbar(graphQLError, { variant: 'error' })
      }
    },
    [params, history, confirmEmail],
  )

  return (
    <Stack flexGrow={1}>
      <AuthHeader />

      <Stack flexGrow={1} px={4}>
        <Typography align="center" variant="h5">
          Verify your account
        </Typography>

        {!!params.email && (
          <Typography align="center" color="text.secondary" mt={1.5}>
            Verification code has been send to{' '}
            <Typography
              color="text.primary"
              component="span"
              variant="subtitle1"
            >
              {params.email}
            </Typography>
          </Typography>
        )}

        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack mt={4} spacing={5}>
            <Controller
              control={control}
              name={Fields.Code}
              render={({ field: { value, onChange } }) => (
                <MuiOtpInput
                  TextFieldsProps={{
                    type: 'number',
                  }}
                  justifyContent="center"
                  length={CODE_LENGTH}
                  value={value}
                  onChange={onChange}
                />
              )}
            />

            <LoadingButton
              loading={isSubmitting}
              size="large"
              type="submit"
              variant="contained"
            >
              Submit code
            </LoadingButton>
          </Stack>
        </form>
      </Stack>
    </Stack>
  )
}

export default VerifyEmailPage
