import { ApolloCache } from '@apollo/client'

import omit from 'lodash/omit'

import {
  ArticleRecordFragment,
  ArticleRecordFragmentDoc,
} from 'GraphQL/DatoCMS/TypedDocuments'
import { UserFragment } from 'GraphQL/Main/TypedDocuments'

interface BaseParams {
  cache: ApolloCache<any>
  id: ArticleRecordFragment['id']
}

interface AddBookmarkParams extends BaseParams {
  viewerId: UserFragment['id']
}

// TODO: Add bookmark to the list of bookmarks
function addBookmark({ cache, id, viewerId }: AddBookmarkParams) {
  cache.updateFragment<ArticleRecordFragment>(
    {
      id: `ArticleRecord:${id}`,
      fragment: ArticleRecordFragmentDoc,
      fragmentName: 'ArticleRecord',
    },
    data =>
      data
        ? {
            ...data,
            bookmarkedBy: { ...data.bookmarkedBy, [viewerId]: true },
          }
        : null,
  )
}

interface RemoveBookmarkParams extends BaseParams {
  viewerId: UserFragment['id']
}

function removeBookmark({ cache, id, viewerId }: RemoveBookmarkParams) {
  cache.updateFragment<ArticleRecordFragment>(
    {
      id: `ArticleRecord:${id}`,
      fragment: ArticleRecordFragmentDoc,
      fragmentName: 'ArticleRecord',
    },
    data =>
      data
        ? {
            ...data,
            bookmarkedBy: omit(data.bookmarkedBy, viewerId),
          }
        : null,
  )
}

interface AddLikeParams extends BaseParams {
  viewerId: UserFragment['id']
}

function addLike({ cache, id, viewerId }: AddLikeParams) {
  cache.updateFragment<ArticleRecordFragment>(
    {
      id: `ArticleRecord:${id}`,
      fragment: ArticleRecordFragmentDoc,
      fragmentName: 'ArticleRecord',
    },
    data =>
      data
        ? {
            ...data,
            likedBy: { ...data.likedBy, [viewerId]: true },
          }
        : null,
  )
}

interface RemoveLikeParams extends BaseParams {
  viewerId: UserFragment['id']
}

function removeLike({ cache, id, viewerId }: RemoveLikeParams) {
  cache.updateFragment<ArticleRecordFragment>(
    {
      id: `ArticleRecord:${id}`,
      fragment: ArticleRecordFragmentDoc,
      fragmentName: 'ArticleRecord',
    },
    data =>
      data
        ? {
            ...data,
            likedBy: omit(data.likedBy, viewerId),
          }
        : null,
  )
}

interface AddShareParams extends BaseParams {
  viewerId: UserFragment['id']
}

function addShare({ cache, id, viewerId }: AddShareParams) {
  cache.updateFragment<ArticleRecordFragment>(
    {
      id: `ArticleRecord:${id}`,
      fragment: ArticleRecordFragmentDoc,
      fragmentName: 'ArticleRecord',
    },
    data =>
      data
        ? {
            ...data,
            sharedBy: { ...data.sharedBy, [viewerId]: true },
          }
        : null,
  )
}

const ArticleRecordUpdater = {
  addBookmark,
  removeBookmark,
  addLike,
  removeLike,
  addShare,
}

export default ArticleRecordUpdater
