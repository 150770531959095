import React, { useEffect, useState } from 'react'

import { Box } from '@mui/material'

import { Player } from './styles'

type Props = {
  id: string
}

function VimeoPlayer({ id }: Props) {
  const [aspectRatio, setAspectRatio] = useState(0)

  const url = `https://player.vimeo.com/video/${id}`

  useEffect(() => {
    async function fetchMetadata() {
      const response = await fetch(
        `https://vimeo.com/api/oembed.json?url=${url}`,
      )
      const metadata = await response.json()
      setAspectRatio(metadata.width / metadata.height)
    }

    fetchMetadata()
  }, [url])

  if (!aspectRatio) return null

  return (
    <Box position="relative" sx={{ width: '100%', aspectRatio, flexShrink: 0 }}>
      <Player
        allow="autoplay; fullscreen; picture-in-picture"
        src={`https://player.vimeo.com/video/${id}`}
        title="Vimeo player"
      />
    </Box>
  )
}

export default VimeoPlayer
