import { split } from '@apollo/client'

import { DATOCMS, MAIN } from 'Config'

import Shared from 'Services/Shared'

import createHttpLink from './createHTTPLink'
import {
  isAdminOperation,
  isDatoCMSOperation,
  isSubscriptionOperation,
} from './utils'
import WebSocketLink from './WebSocket'

function createMainSubscriptionLink() {
  const link = new WebSocketLink(`${MAIN.WEBSOCKETS_URL}/graphql`)
  Shared.setMainSubscriptionLink(link)
  return link
}

function createMainLink() {
  return split(
    isAdminOperation,
    createHttpLink(`${MAIN.API_URL}/admin/graphql`),
    createHttpLink(`${MAIN.API_URL}/graphql`),
  )
}

export default function createRequestLink() {
  return split(
    isDatoCMSOperation,
    createHttpLink(DATOCMS.API_URL),
    split(
      isSubscriptionOperation,
      // @ts-ignore
      createMainSubscriptionLink(),
      createMainLink(),
    ),
  )
}
