import React from 'react'
import { Helmet } from 'react-helmet'

import { useTheme } from 'styled-components'

function Fonts() {
  const theme = useTheme()

  return (
    <Helmet>
      {theme?.webfonts.map(font => (
        <link
          href={`https://fonts.googleapis.com/css?family=${font}`}
          key={font}
          rel="stylesheet"
        />
      ))}
    </Helmet>
  )
}

export default Fonts
