import { ApolloLink, HttpLink } from '@apollo/client'
import { BatchHttpLink } from '@apollo/client/link/batch-http'

import { isBatchOperation } from './utils'

export default function createHTTPLink(uri: string) {
  return ApolloLink.split(
    isBatchOperation,
    new BatchHttpLink({
      uri,
      headers: { Batch: 'true' },
    }),
    new HttpLink({ uri }),
  )
}
