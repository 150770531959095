import React, { useCallback, useMemo, useState } from 'react'

import { useQuery } from '@apollo/client'
import {
  ChatBubbleOutline,
  ChevronRight,
  VideocamOutlined,
} from '@mui/icons-material'
import { Button, Divider, Link, Stack, Typography } from '@mui/material'
import numbro from 'numbro'

import {
  MainHeader,
  SubscriptionAIDialog,
  VimeoPlayer,
} from 'Components/Blocks'

import * as paths from 'Constants/paths'

import { WeekBillingDocument } from 'GraphQL/Main/TypedDocuments'

import { useAppContext } from 'Services/AppContext'
import { PRICE_PER_MINUTE } from 'Services/Billing'

import { Feature } from './styles'

const PRICE_FORMAT_CONFIG = {
  spaceSeparated: false,
  mantissa: 2,
}

// TODO: Replace with an actual video
function AIPage() {
  const [subscriptionDialogOpen, setSubscriptionDialogOpen] = useState(false)

  const { viewer } = useAppContext()

  const { data: weekBillingData } = useQuery(WeekBillingDocument, {
    skip: !viewer?.userSubscription,
    fetchPolicy: 'cache-and-network',
  })

  const handleFeatureClick = useCallback(() => {
    if (!viewer?.userSubscription) {
      setSubscriptionDialogOpen(true)
    }
  }, [viewer])

  const mainHeaderBottom = useMemo(
    () => (
      <Stack direction="row" spacing={1.5}>
        <Link
          href={viewer?.userSubscription ? paths.AI_TEXT : '#'}
          sx={{ flexGrow: 1 }}
          onClick={handleFeatureClick}
        >
          <Feature p={1.5} spacing={2}>
            <ChatBubbleOutline sx={{ color: 'primary.main', fontSize: 32 }} />

            <Stack>
              <Typography variant="subtitle1">Chat (text/audio)</Typography>
              <Typography color="text.disabled" variant="overline">
                Free
              </Typography>
            </Stack>
          </Feature>
        </Link>

        {/* <Link
          href={viewer?.userSubscription ? paths.AI_AUDIO : '#'}
          sx={{ flexGrow: 1 }}
          onClick={handleFeatureClick}
        >
          <Feature p={1.5} spacing={2}>
            <Voicemail sx={{ color: 'primary.main', fontSize: 32 }} />

            <Stack>
              <Typography variant="subtitle1">Call</Typography>
              <Typography color="text.disabled" variant="overline">
                {numbro(PRICE_PER_MINUTE.CALL).formatCurrency(
                  PRICE_FORMAT_CONFIG,
                )}
                /min
              </Typography>
            </Stack>
          </Feature>
        </Link> */}

        <Link
          href={viewer?.userSubscription ? paths.AI_VIDEO : '#'}
          sx={{ flexGrow: 1 }}
          onClick={handleFeatureClick}
        >
          <Feature p={1.5} spacing={2}>
            <VideocamOutlined sx={{ color: 'primary.main', fontSize: 32 }} />

            <Stack>
              <Typography variant="subtitle1">Video</Typography>
              <Typography color="text.disabled" variant="overline">
                {numbro(PRICE_PER_MINUTE.VIDEO).formatCurrency(
                  PRICE_FORMAT_CONFIG,
                )}
                /min
              </Typography>
            </Stack>
          </Feature>
        </Link>
      </Stack>
    ),
    [viewer, handleFeatureClick],
  )

  return (
    <Stack flexGrow={1} spacing={2}>
      <MainHeader
        bottom={mainHeaderBottom}
        description="Talk to Dr Mark van Rijmenam's digital twin!"
        noBackground
        title="AI Chat"
      />

      <Stack px={2} spacing={2}>
        {weekBillingData && (
          <>
            <Stack spacing={2}>
              <Stack alignItems="center" direction="row" spacing={1}>
                <Typography flexGrow={1} noWrap variant="h6">
                  Usage
                </Typography>

                <Button
                  endIcon={<ChevronRight />}
                  href={paths.SUBSCRIPTION_USAGE}
                  size="small"
                >
                  Show more
                </Button>
              </Stack>

              <Stack spacing={2}>
                {/* <Stack alignItems="center" direction="row" spacing={1.5}>
                  <ChatBubbleOutline
                    sx={{ fontSize: 20, color: 'text.secondary' }}
                  />
                  <Typography variant="subtitle1">Сhat</Typography>
                  <Divider sx={{ flexGrow: 1, pt: 1.5 }} />
                  <Typography color="text.secondary">
                    {weekBillingData.weekBilling.textMinutes} min.
                  </Typography>
                </Stack> */}
                {/* 
                <Stack alignItems="center" direction="row" spacing={1.5}>
                  <Voicemail sx={{ fontSize: 20, color: 'text.secondary' }} />
                  <Typography variant="subtitle1">Call</Typography>
                  <Divider sx={{ flexGrow: 1, pt: 1.5 }} />
                  <Typography color="text.secondary">
                    {weekBillingData.weekBilling.audioMinutes} min.
                  </Typography>
                </Stack> */}

                <Stack alignItems="center" direction="row" spacing={1.5}>
                  <VideocamOutlined
                    sx={{ fontSize: 20, color: 'text.secondary' }}
                  />
                  <Typography variant="subtitle1">Video</Typography>
                  <Divider sx={{ flexGrow: 1, pt: 1.5 }} />
                  <Typography color="text.secondary">
                    {weekBillingData.weekBilling.videoMinutes} min.
                  </Typography>
                </Stack>
              </Stack>
            </Stack>

            <Divider />
          </>
        )}

        <Stack spacing={2}>
          <Typography flexGrow={1} noWrap variant="h6">
            How it works
          </Typography>

          <VimeoPlayer id="894344820" />
        </Stack>
      </Stack>

      <SubscriptionAIDialog
        open={subscriptionDialogOpen}
        onRequestClose={() => setSubscriptionDialogOpen(false)}
      />
    </Stack>
  )
}

export default AIPage
