import React from 'react'
import { StructuredText } from 'react-datocms'

import { useQuery } from '@apollo/client'
import { Box, Stack } from '@mui/material'

import { TitleHeader } from 'Components/Blocks'

import { DatoCmsTermsOfServicePageDocument } from 'GraphQL/DatoCMS/TypedDocuments'

function TermsOfServicePage() {
  const { data } = useQuery(DatoCmsTermsOfServicePageDocument)

  return (
    <Stack flexGrow={1} overflow="hidden">
      <TitleHeader title="Terms & Conditions" />

      <Box overflow="auto" p={2}>
        <StructuredText data={data?.termsOfServicePage?.content} />
      </Box>
    </Stack>
  )
}

export default TermsOfServicePage
