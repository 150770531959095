import { TokenRefreshLink } from 'apollo-link-token-refresh'
import axios from 'axios'
import { print } from 'graphql'
import { jwtDecode } from 'jwt-decode'

import identity from 'lodash/identity'

import { MAIN } from 'Config'

import { RefreshTokenDocument } from 'GraphQL/Main/TypedDocuments'

import Auth from 'Services/Auth'
import {
  getAccessToken,
  getRefreshToken,
  set as setSession,
} from 'Services/Store/session'

const THRESHOLD = 90 // NOTE: Invalidate 90 seconds before expiration
const URL = `${MAIN.API_URL}/graphql`

export default new TokenRefreshLink({
  accessTokenField: 'accessToken',

  isTokenValidOrUndefined: async () => {
    const token = getAccessToken()

    if (!token) {
      return true
    }

    try {
      const { exp } = jwtDecode(token)

      if (exp) {
        return Date.now() < (exp - THRESHOLD) * 1000
      }
    } catch (error) {
      //
    }

    return false
  },

  fetchAccessToken: async () => {
    const refreshToken = getRefreshToken()

    const { data } = await axios({
      url: URL,
      method: 'POST',
      data: {
        query: print(RefreshTokenDocument),
        variables: { token: refreshToken },
      },
    })

    return data?.data.refreshToken
  },

  handleFetch: accessToken => {
    setSession({ accessToken })
  },

  handleResponse: identity,

  handleError: () => {
    // eslint-disable-next-line no-console
    console.log('Refresh failed. Should clear and logout')
    Auth.logOut().then()
  },
}) as any
