import React from 'react'
import { useParams } from 'react-router'

import { useQuery } from '@apollo/client'
import { Stack } from '@mui/material'

import { Browser, TitleHeader } from 'Components/Blocks'

import {
  DatoCmsLectureDocument,
  LectureRecordFragment,
} from 'GraphQL/DatoCMS/TypedDocuments'

type RouteParams = {
  lectureId?: LectureRecordFragment['id']
}

function LectureQuizPage() {
  const { lectureId } = useParams<RouteParams>()

  const { data } = useQuery(DatoCmsLectureDocument, {
    variables: { filter: { id: { eq: lectureId } } },
    skip: !lectureId,
  })

  const lecture = data?.lecture

  return (
    <Stack flexGrow={1} overflow="hidden">
      <TitleHeader title={lecture?.title ? `Quiz: ${lecture.title}` : 'Quiz'} />

      {!!lecture?.embeddableTfLink && (
        <Browser src={lecture.embeddableTfLink} title="Quiz" />
      )}
    </Stack>
  )
}

export default LectureQuizPage
