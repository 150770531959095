import { Button } from '@mui/material'
import { styled } from '@mui/system'

type ActionButtonProps = {
  active?: boolean
}

export const ActionButton = styled(Button, {
  shouldForwardProp: prop => prop !== 'active',
})<ActionButtonProps>(({ theme, active }) => ({
  flex: 1,
  color: active ? theme.palette.primary.light : theme.palette.text.secondary,
  overflow: 'hidden',
}))
