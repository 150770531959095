import React, { useCallback, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router'

import { Star } from '@mui/icons-material'
import { Button, Divider, Stack, Tab, Tabs, Typography } from '@mui/material'
import numbro from 'numbro'
import qs from 'qs'

import { Markdown, SubscriptionDialog, VimeoPlayer } from 'Components/Blocks'

import { InsightRecordFragment } from 'GraphQL/DatoCMS/TypedDocuments'
import { SubscriptionPlanKind } from 'GraphQL/Main/TypedDocuments'

import { useAppContext } from 'Services/AppContext'

import LecturesTab from './LecturesTab'
import OverviewTab from './OverviewTab'

type Props = {
  insight: InsightRecordFragment
}

const COUNT_FORMAT_CONFIG = {
  spaceSeparated: false,
  average: true,
  mantissa: 1,
  optionalMantissa: true,
}

enum TabEnum {
  Overview = 'overview',
  Lectures = 'lectures',
}

const TAB_SEARCH_PARAM = 'tab'

function Masterclass({ insight }: Props) {
  const [subscriptionDialogOpen, setSubscriptionDialogOpen] = useState(false)

  const history = useHistory()
  const { search } = useLocation()
  const { viewer } = useAppContext()

  const activeTab = useMemo(
    () => new URLSearchParams(search).get(TAB_SEARCH_PARAM) ?? TabEnum.Overview,
    [search],
  )

  const values = useMemo(() => {
    return {
      listenerCount: numbro(insight.listenersCount ?? 0).format(
        COUNT_FORMAT_CONFIG,
      ),
    }
  }, [insight])

  const handleTabChange = useCallback(
    (event: React.SyntheticEvent, nextValue: number) => {
      history.push({
        search: qs.stringify({ [TAB_SEARCH_PARAM]: nextValue }),
      })
    },
    [history],
  )

  const handleStartLearningClick = useCallback(() => {
    history.push({
      search: qs.stringify({ [TAB_SEARCH_PARAM]: TabEnum.Lectures }),
    })
  }, [history])

  const content = useMemo(() => {
    if (viewer?.userSubscription?.planKind !== SubscriptionPlanKind.Expert) {
      return (
        <Stack flexGrow={1} spacing={2}>
          <Stack flexGrow={1}>
            <Markdown>{insight.text}</Markdown>
          </Stack>
        </Stack>
      )
    }

    return (
      <Stack flexGrow={1}>
        <Tabs value={activeTab} variant="fullWidth" onChange={handleTabChange}>
          <Tab label="Overview" value={TabEnum.Overview} />
          <Tab label="Lectures" value={TabEnum.Lectures} />
        </Tabs>

        {activeTab === TabEnum.Overview && (
          <OverviewTab insight={insight} pt={2} />
        )}

        {activeTab === TabEnum.Lectures && (
          <LecturesTab insight={insight} pt={2} />
        )}
      </Stack>
    )
  }, [activeTab, insight, viewer, handleTabChange])

  const floatingButton = useMemo(() => {
    if (activeTab && activeTab !== TabEnum.Overview) {
      return null
    }

    if (viewer?.userSubscription?.planKind !== SubscriptionPlanKind.Expert) {
      return (
        <Stack p={2}>
          <Button
            variant="contained"
            onClick={() => setSubscriptionDialogOpen(true)}
          >
            Subscribe to get full access
          </Button>
        </Stack>
      )
    }

    return (
      <Stack p={2}>
        <Button variant="contained" onClick={handleStartLearningClick}>
          Start learning
        </Button>
      </Stack>
    )
  }, [viewer, activeTab, handleStartLearningClick])

  return (
    <Stack flexGrow={1} overflow="hidden">
      {!!insight.vimeoId && <VimeoPlayer id={insight.vimeoId} />}

      <Stack overflow="auto">
        <Stack flexGrow={1} p={2} spacing={2}>
          <Stack spacing={1}>
            <Typography variant="h6">{insight.title}</Typography>

            <Stack direction="row" spacing={1}>
              <Stack direction="row" spacing={0.5}>
                <Star color="warning" sx={{ fontSize: 16 }} />

                <Typography color="text.secondary" variant="caption">
                  {insight.rating}
                </Typography>
              </Stack>

              <Divider orientation="vertical" />

              <Typography color="text.secondary" variant="caption">
                {values.listenerCount} Learners
              </Typography>
            </Stack>
          </Stack>

          {content}
        </Stack>
      </Stack>

      {floatingButton}

      <SubscriptionDialog
        open={subscriptionDialogOpen}
        onRequestClose={() => setSubscriptionDialogOpen(false)}
      />
    </Stack>
  )
}

export default Masterclass
