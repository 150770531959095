import React from 'react'
import { useHistory } from 'react-router'

import { ChevronLeft, Close } from '@mui/icons-material'
import { Box, IconButton, Stack } from '@mui/material'

import LogoImage from 'Components/Blocks/LogoImage'

import { SafeAreaView } from 'Services/SafeArea'

type Props = {
  canGoBack?: boolean
  closeable?: boolean
  onGoBack?: () => void
  onClose?: () => void
}

// TODO: Rename into something more generic. This header is also in use on subscription page
function AuthHeader({ closeable, canGoBack = true, onClose, onGoBack }: Props) {
  const { goBack } = useHistory()

  return (
    <SafeAreaView top>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="center"
        pb={4}
        pt={1}
      >
        {!closeable && canGoBack && (
          <Box left={0} ml={1} position="absolute">
            <IconButton
              size="large"
              onClick={() => (onGoBack ? onGoBack() : goBack())}
            >
              <ChevronLeft sx={{ fontSize: '24px' }} />
            </IconButton>
          </Box>
        )}

        <LogoImage height={72} width={88} />

        {closeable && (
          <Box mr={1} position="absolute" right={0}>
            <IconButton size="large" onClick={onClose}>
              <Close sx={{ fontSize: '24px' }} />
            </IconButton>
          </Box>
        )}
      </Stack>
    </SafeAreaView>
  )
}

export default AuthHeader
