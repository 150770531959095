import React, { useCallback, useMemo, useState } from 'react'
import { ItemContent, Virtuoso } from 'react-virtuoso'

import { useQuery } from '@apollo/client'
import { ChevronRight, Refresh, Search } from '@mui/icons-material'
import {
  CircularProgress,
  Divider,
  Fab,
  IconButton,
  Link,
  Stack,
  Typography,
} from '@mui/material'
import { DateTime } from 'luxon'
import { useDebounce } from 'use-debounce'

import { ArticleItem, MainHeader, SearchHeader } from 'Components/Blocks'

import * as paths from 'Constants/paths'

import {
  ArticleModelOrderBy,
  ArticleRecordFragment,
  DatoCmsAllArticlesDocument,
  DatoCmsAllArticlesQueryVariables,
} from 'GraphQL/DatoCMS/TypedDocuments'

import { useAppContext } from 'Services/AppContext'

function FeedPage() {
  const [searchEnabled, setSearchEnabled] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [debouncedSearchTerm] = useDebounce(searchTerm, 700)
  const [refreshing, setRefreshing] = useState(false)

  const { viewer } = useAppContext()

  const allArticlesFilter = useMemo(() => {
    const result: DatoCmsAllArticlesQueryVariables['filter'] = {}

    if (debouncedSearchTerm) {
      result.title = {
        matches: {
          pattern: debouncedSearchTerm,
        },
      }
    }
    if (!viewer?.userSubscription) {
      // eslint-disable-next-line no-underscore-dangle
      result._createdAt = {
        lte: DateTime.local().minus({ month: 1 }).toISO(),
      }
    }

    return result
  }, [viewer, debouncedSearchTerm])

  const { data, refetch, fetchMore } = useQuery(DatoCmsAllArticlesDocument, {
    variables: {
      filter: allArticlesFilter,
      orderBy: ArticleModelOrderBy.CreatedAtDesc,
      first: 10,
    },
  })

  const articles = useMemo(() => data?.allArticles ?? [], [data])

  const enableSearch = useCallback(() => {
    setSearchEnabled(true)
  }, [])

  const disableSearch = useCallback(() => {
    setSearchEnabled(false)
    setSearchTerm('')
  }, [])

  const loadMore = useCallback(async () => {
    if (!articles.length) return

    await fetchMore({
      variables: {
        skip: articles.length,
      },
    })
  }, [articles, fetchMore])

  const handleRefresh = useCallback(async () => {
    setRefreshing(true)
    await refetch()
    setRefreshing(false)
  }, [refetch])

  const renderItemContent = useCallback<
    ItemContent<ArticleRecordFragment, any>
  >(
    (index, item) => (
      <Stack>
        {index > 0 && <Divider sx={{ mx: 2 }} />}
        <ArticleItem item={item} p={2} />
      </Stack>
    ),
    [],
  )

  return (
    <Stack flexGrow={1} position="relative">
      {searchEnabled ? (
        <SearchHeader
          searchTerm={searchTerm}
          onClose={disableSearch}
          onSearchTermChange={setSearchTerm}
        />
      ) : (
        <MainHeader
          description="Recommendations by Dr Mark van Rijmenam"
          right={
            <IconButton onClick={enableSearch}>
              <Search />
            </IconButton>
          }
          title="Feed"
        />
      )}

      {!!viewer && !viewer.userSubscription && (
        <Link href={paths.SUBSCRIPTION}>
          <Stack
            bgcolor="primary.main"
            color="background.default"
            direction="row"
            px={2}
            py={1.5}
          >
            <Typography flexGrow={1} noWrap variant="subtitle2">
              Subscribe for real-time updates
            </Typography>

            <ChevronRight />
          </Stack>
        </Link>
      )}

      <Virtuoso
        data={articles}
        endReached={loadMore}
        itemContent={renderItemContent}
      />

      <Fab
        sx={theme => ({
          position: 'absolute',
          bottom: theme.spacing(2),
          right: theme.spacing(2),
        })}
        onClick={handleRefresh}
      >
        {refreshing ? (
          <CircularProgress color="inherit" size={24} />
        ) : (
          <Refresh color="inherit" />
        )}
      </Fab>
    </Stack>
  )
}

export default FeedPage
