import { defaultDataIdFromObject } from '@apollo/client'
import { KeyFieldsFunction } from '@apollo/client/cache/inmemory/policies'

const dataIdFromObject: KeyFieldsFunction = responseObject => {
  // eslint-disable-next-line no-underscore-dangle
  switch (responseObject.__typename) {
    case 'UserProfile':
      return `UserProfile:${responseObject.userId}`
    default:
      return defaultDataIdFromObject(responseObject)
  }
}

export default dataIdFromObject
