import React, { useCallback } from 'react'
import { useHistory } from 'react-router'

import { Box, Button, Link, Stack, Typography } from '@mui/material'

import { AuthHeader, VimeoPlayer } from 'Components/Blocks'

import * as paths from 'Constants/paths'

import { SafeAreaView } from 'Services/SafeArea'

// TODO: Replace with an actual video
function SubscriptionSuccessPage() {
  const history = useHistory()

  const handleClose = useCallback(() => history.replace(paths.ROOT), [history])

  return (
    <Stack flexGrow={1}>
      <AuthHeader closeable onClose={handleClose} />

      <Box pb={2} px={4}>
        <Typography align="center" variant="h5">
          Thank you!
        </Typography>

        <Stack flexGrow={1} mt={1.5} spacing={4}>
          <Typography align="justify" color="text.secondary">
            Welcome to my personal network where I share valuable insights on
            emerging technologies. Now that you are subscribed, you can also
            text, call or video call with my digital twin and ask it anything
            about emerging technologies.
            <br />
            <br />
            If you have any questions, feel free to reach out to me via the
            contact form or via{' '}
            <Link component="a" href="mailto:enquiries@thedigitalspeaker.com">
              enquiries@thedigitalspeaker.com
            </Link>
          </Typography>

          <VimeoPlayer id="893949034" />

          <Button sx={{ mt: 4 }} variant="contained" onClick={handleClose}>
            Close
          </Button>
        </Stack>
      </Box>

      <SafeAreaView bottom />
    </Stack>
  )
}

export default SubscriptionSuccessPage
