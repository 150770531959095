import { createContext, useContext } from 'react'

import { UserFragment } from 'GraphQL/Main/TypedDocuments'

type AppContextValue = {
  viewer?: UserFragment
  initialLoading?: boolean
}

const AppContext = createContext<AppContextValue>({})

function useAppContext() {
  return useContext(AppContext)
}

export { AppContext, useAppContext }
