import React, { useMemo } from 'react'

import { Box, Link, Stack, Typography } from '@mui/material'
import { PaddingProps } from '@mui/system'
import { DateTime } from 'luxon'
import { Image } from 'mui-image'

import * as paths from 'Constants/paths'

import { InsightRecordFragment } from 'GraphQL/DatoCMS/TypedDocuments'

import { InsightType } from 'Interfaces/Enums'

type Props = PaddingProps & {
  item: InsightRecordFragment
}

function InsightItem({ item, ...rest }: Props) {
  const values = useMemo(() => {
    const type = (() => {
      switch (item.insightType) {
        case InsightType.Article:
          return 'Article'
        case InsightType.Podcast:
          return 'Podcast'
        case InsightType.Masterclass:
          return 'Masterclass'
        default:
          return ''
      }
    })()

    return {
      type,
      date: DateTime.fromISO(
        // eslint-disable-next-line no-underscore-dangle
        item._createdAt,
      ).toLocaleString(DateTime.DATE_MED),
    }
  }, [item])

  const image = useMemo(() => {
    if (item.articleImage) {
      return (
        <Box flexShrink={0}>
          <Image
            alt={item.title || ''}
            height="68px"
            src={item.articleImage}
            sx={{ borderRadius: 3 }}
            width="68px"
          />
        </Box>
      )
    }

    return null
  }, [item])

  const content = (
    <Stack {...rest} direction="row" spacing={2}>
      {image}

      <Stack flexGrow={1} spacing={1}>
        <Typography variant="subtitle1">{item.title}</Typography>

        <Typography color="text.secondary" variant="caption">
          {values.type} • {values.date}
        </Typography>
      </Stack>
    </Stack>
  )

  return (
    <Link color="inherit" href={paths.INSIGHT(item.id)}>
      {content}
    </Link>
  )
}

export default InsightItem
