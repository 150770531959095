import React, { useCallback, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory } from 'react-router'

import { useMutation } from '@apollo/client'
import { zodResolver } from '@hookform/resolvers/zod'
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material'
import {
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Link,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { enqueueSnackbar } from 'notistack'
import Utils from 'Utils'
import { z } from 'zod'

import { AuthHeader } from 'Components/Blocks'

import * as paths from 'Constants/paths'
import { PASSWORD_REGEXP } from 'Constants/regexps'

import { SignUpByEmailDocument } from 'GraphQL/Main/TypedDocuments'

import { VerifyEmailPageParam } from 'Interfaces/Enums'

enum Fields {
  FirstName = 'firstName',
  LastName = 'lastName',
  Email = 'email',
  Password = 'password',
  ConfirmPassword = 'confirmPassword',
  SubscribeToMailList = 'subscribeToMailList',
}

type Values = z.infer<typeof schema>

const schema = z
  .object({
    [Fields.FirstName]: z.string(),
    [Fields.LastName]: z.string(),
    [Fields.Email]: z.string().email('Must be a valid email'),
    [Fields.Password]: z
      .string()
      .min(1, { message: 'Password is required' })
      .min(8, { message: 'Password should have at least 8 characters' })
      .regex(PASSWORD_REGEXP, "Password requirements weren't met"),
    [Fields.ConfirmPassword]: z
      .string()
      .min(1, { message: 'Password is required' })
      .min(8, { message: 'Password should have at least 8 characters' })
      .regex(PASSWORD_REGEXP, "Password requirements weren't met"),
    [Fields.SubscribeToMailList]: z.optional(z.boolean()),
  })
  .refine(data => data[Fields.Password] === data[Fields.ConfirmPassword], {
    message: "Passwords don't match",
    path: [Fields.ConfirmPassword],
  })

function SignUpPage() {
  const [showPassword, setShowPassword] = useState(false)

  const history = useHistory()

  const [signUpByEmail] = useMutation(SignUpByEmailDocument)

  const { control, handleSubmit } = useForm<Values>({
    resolver: zodResolver(schema),
    defaultValues: {
      [Fields.FirstName]: '',
      [Fields.LastName]: '',
      [Fields.Email]: '',
      [Fields.Password]: '',
      [Fields.ConfirmPassword]: '',
      [Fields.SubscribeToMailList]: true,
    },
  })

  const handlePasswordIconClick = useCallback(
    () => setShowPassword(show => !show),
    [],
  )

  const onSubmit = useCallback(
    async (values: Values) => {
      try {
        const emailField = values[Fields.Email]

        await signUpByEmail({
          variables: {
            email: emailField,
            password: values[Fields.Password],
            firstName: values[Fields.FirstName],
            lastName: values[Fields.LastName],
            subscribeToMailList: values[Fields.SubscribeToMailList],
          },
        })

        const searchParams = new URLSearchParams()
        searchParams.append(VerifyEmailPageParam.Email, emailField)

        history.push({
          pathname: paths.VERIFY_EMAIL,
          search: searchParams.toString(),
        })

        enqueueSnackbar(
          "We've sent the confirmation code to your email. Please check your email to finish the registration and start exploring your account.",
          { variant: 'success' },
        )
      } catch (error) {
        const [graphQLError] = Utils.Errors.getGraphQLErrors(error)
        enqueueSnackbar(graphQLError, { variant: 'error' })
      }
    },
    [history, signUpByEmail],
  )

  return (
    <Stack flexGrow={1}>
      <AuthHeader />

      <Stack flexGrow={1} px={5}>
        <Typography align="center" variant="h5">
          Create account
        </Typography>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack mt={4} spacing={4}>
            <Stack spacing={2.5}>
              <Controller
                control={control}
                name={Fields.FirstName}
                render={({ field: { value, onChange }, fieldState }) => (
                  <TextField
                    autoCapitalize="none"
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    label="First name"
                    value={value}
                    variant="standard"
                    onChange={onChange}
                  />
                )}
              />

              <Controller
                control={control}
                name={Fields.LastName}
                render={({ field: { value, onChange }, fieldState }) => (
                  <TextField
                    autoCapitalize="none"
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    label="Last name"
                    value={value}
                    variant="standard"
                    onChange={onChange}
                  />
                )}
              />

              <Controller
                control={control}
                name={Fields.Email}
                render={({ field: { value, onChange }, fieldState }) => (
                  <TextField
                    autoCapitalize="none"
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    inputProps={{
                      type: 'email',
                    }}
                    label="Email"
                    value={value}
                    variant="standard"
                    onChange={onChange}
                  />
                )}
              />

              <Controller
                control={control}
                name={Fields.Password}
                render={({ field: { value, onChange }, fieldState }) => (
                  <TextField
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            onClick={handlePasswordIconClick}
                          >
                            {showPassword ? (
                              <VisibilityOffOutlined />
                            ) : (
                              <VisibilityOutlined />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    autoComplete="true"
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error?.message ??
                      'Must be at least 8 characters with 1 uppercase letter, 1 symbol and 1 number'
                    }
                    label="Password"
                    type={showPassword ? 'text' : 'password'}
                    value={value}
                    variant="standard"
                    onChange={onChange}
                  />
                )}
              />

              <Controller
                control={control}
                name={Fields.ConfirmPassword}
                render={({ field: { value, onChange }, fieldState }) => (
                  <TextField
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            onClick={handlePasswordIconClick}
                          >
                            {showPassword ? (
                              <VisibilityOffOutlined />
                            ) : (
                              <VisibilityOutlined />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    autoComplete="true"
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    label="Confirm password"
                    type={showPassword ? 'text' : 'password'}
                    value={value}
                    variant="standard"
                    onChange={onChange}
                  />
                )}
              />
            </Stack>

            <Stack spacing={3}>
              <Controller
                control={control}
                name={Fields.SubscribeToMailList}
                render={({ field: { value, onChange } }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={value}
                        inputProps={{ 'aria-label': 'controlled' }}
                        onChange={onChange}
                      />
                    }
                    label={
                      <Typography variant="subtitle2">
                        Subscribe to the Synthetic Minds newsletter
                      </Typography>
                    }
                    sx={{ alignSelf: 'center' }}
                  />
                )}
              />

              <Typography
                align="center"
                color="text.secondary"
                variant="caption"
              >
                By continuing, you agree to our{' '}
                <Link href={paths.TERMS_OF_SERVICE} target="_blank">
                  Terms of Service
                </Link>{' '}
                and{' '}
                <Link href={paths.PRIVACY_POLICY} target="_blank">
                  Privacy Policy
                </Link>
              </Typography>

              <Button size="large" type="submit" variant="contained">
                Continue
              </Button>
            </Stack>
          </Stack>
        </form>
      </Stack>
    </Stack>
  )
}

export default SignUpPage
