import { onError } from '@apollo/client/link/error'

import forEach from 'lodash/forEach'

import Auth from 'Services/Auth'

export default function createErrorLink() {
  return onError(
    ({ response, graphQLErrors, networkError, operation, forward }) => {
      if (networkError)
        console.error(
          `[Network error]: ${networkError} ${JSON.stringify({
            response,
            name: operation.operationName,
            variables: operation.variables,
            graphQLErrors,
          })}`,
        )

      const is401 =
        networkError &&
        'statusCode' in networkError &&
        networkError.statusCode === 401

      const isUnauthorizedError =
        is401 ||
        graphQLErrors?.[0]?.message === 'generic.notAuthorized' ||
        graphQLErrors?.[0]?.message === 'Anonymous access is denied.'

      // NOTE: It's possible to get this error in rare edge cases like lack of session in redis
      //       due to infrastructure update etc. We can't do much here, because both our tokens
      //       are invalid.
      if (isUnauthorizedError) {
        Auth.logOut()
        forward(operation)
        return
      }

      if (graphQLErrors) {
        forEach(graphQLErrors, ({ message, path, ...rest }) => {
          if (message === 'PersistedQueryNotFound') return

          console.log(
            `[GraphQL error]: Operation: ${
              operation?.operationName
            } Message: ${message}, Path: ${path} Info: ${JSON.stringify(rest)}`,
          )
        })

        forward(operation)
      }

      if (operation.operationName === 'Me' && response) {
        response.errors = undefined
      }
    },
  )
}
