import React, { useEffect } from 'react'
import { useHistory } from 'react-router'

import { Box, CircularProgress, Typography } from '@mui/material'

import * as paths from 'Constants/paths'

import { UserRole } from 'GraphQL/Main/TypedDocuments'

import { useAppContext } from 'Services/AppContext'

function RedirectorPage() {
  const history = useHistory()

  const { viewer } = useAppContext()

  useEffect(() => {
    if (viewer?.role === UserRole.SuperAdmin) {
      history.replace(paths.ADMIN)
      return
    }
    if (viewer?.role === UserRole.User) {
      history.replace(paths.ROOT)
    }
  }, [viewer, history])

  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="column"
      flexGrow={1}
      justifyContent="center"
      p={2}
    >
      <CircularProgress />
      <Typography>Redirecting...</Typography>
    </Box>
  )
}

export default RedirectorPage
