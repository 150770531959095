import React from 'react'
import { Redirect } from 'react-router'
import { RouteConfig } from 'react-router-config'

import * as paths from 'Constants/paths'

import { AuthLayout } from 'Containers/Layout'
import {
  AuthPage,
  RequestResetPasswordPage,
  ResetPasswordPage,
  SignInPage,
  SignUpPage,
  VerifyEmailPage,
} from 'Containers/Pages/Auth'

function authRoutes(): RouteConfig[] {
  const allRoutes: RouteConfig[] = [
    {
      path: paths.AUTH,
      exact: true,
      component: AuthPage,
    },
    {
      path: paths.SIGN_IN,
      exact: true,
      component: SignInPage,
    },
    {
      path: paths.SIGN_UP,
      exact: true,
      component: SignUpPage,
    },
    {
      path: paths.REQUEST_RESET_PASSWORD,
      exact: true,
      component: RequestResetPasswordPage,
    },
    {
      path: paths.RESET_PASSWORD,
      exact: true,
      component: ResetPasswordPage,
    },
    {
      path: paths.VERIFY_EMAIL,
      exact: true,
      component: VerifyEmailPage,
    },
    { component: () => <Redirect to={paths.SIGN_IN} /> },
  ]

  return [
    {
      path: paths.AUTH,
      component: AuthLayout,
      routes: allRoutes,
    },
  ]
}

export default authRoutes
