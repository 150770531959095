import React, { useMemo } from 'react'

import { Check } from '@mui/icons-material'
import { Box, Button, Stack, Typography } from '@mui/material'
import Utils from 'Utils'

import { Markdown } from 'Components/Blocks'

import { SubscriptionPlanRecordFragment } from 'GraphQL/DatoCMS/TypedDocuments'
import { SubscriptionPlanKind } from 'GraphQL/Main/TypedDocuments'

type Props = {
  item: SubscriptionPlanRecordFragment
  currentPlan?: SubscriptionPlanRecordFragment
  onSubscribe?: () => void
}

function PlanItem({ item, currentPlan, onSubscribe }: Props) {
  const planColor = useMemo(() => {
    if (item.kind === SubscriptionPlanKind.Paid) return 'success.light'
    if (item.kind === SubscriptionPlanKind.Expert) return 'warning.light'
    return 'text.secondary'
  }, [item])

  const values = useMemo(() => {
    const isCurrentPlan = Utils.Billing.isCurrentPlan(item, currentPlan)
    const isDowngrade = Utils.Billing.isDowngrade(item, currentPlan)

    return {
      isCurrentPlan,
      isDowngrade,
    }
  }, [item, currentPlan])

  const planButton = useMemo(() => {
    if (values.isCurrentPlan) {
      return (
        <Button disabled size="small" variant="contained">
          Current plan
        </Button>
      )
    }

    return (
      <Button size="small" variant="contained" onClick={onSubscribe}>
        {values.isDowngrade ? 'Downgrade' : 'Subscribe'}
      </Button>
    )
  }, [values, onSubscribe])

  const features = useMemo(() => {
    if (!item.data) return null

    return (
      <Stack mt={1} spacing={1}>
        {item.data.list?.map((dataItem: string) => (
          <Stack
            alignItems="center"
            direction="row"
            key={dataItem}
            spacing={1.5}
          >
            <Check sx={{ color: planColor, fontSize: 20 }} />
            <Markdown>{dataItem}</Markdown>
          </Stack>
        ))}
      </Stack>
    )
  }, [item, planColor])

  return (
    <Box
      bgcolor="background.paper"
      border={1}
      borderColor="divider"
      borderRadius={3}
      padding={1.5}
    >
      <Stack direction="row" justifyContent="space-between">
        <Typography color={planColor} variant="overline">
          {item.name}
        </Typography>

        {planButton}
      </Stack>

      <Stack spacing={1}>
        <Typography variant="h5">{item.title}</Typography>
        <Markdown>{item.content}</Markdown>

        {features}
      </Stack>
    </Box>
  )
}

export default PlanItem
