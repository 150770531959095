import React, { useMemo } from 'react'

import { CountryType } from 'Constants/countries'

type Props = {
  country: CountryType
  size?: number
}

function CountryFlag({ country, size = 20 }: Props) {
  const values = useMemo(() => {
    const code = country.code.toLowerCase()

    return {
      src: `https://flagcdn.com/w${size}/${code}.png`,
      srcSet: `https://flagcdn.com/w${size * 2}/${code}.png 2x`,
    }
  }, [country, size])

  return (
    <img
      alt={country.label}
      src={values.src}
      srcSet={values.srcSet}
      width={size}
    />
  )
}

export default CountryFlag
