import React from 'react'
import { renderRoutes, RouteConfig } from 'react-router-config'

import { Stack } from '@mui/material'

type Props = {
  route?: RouteConfig
}

function AuthLayout({ route }: Props) {
  return <Stack height="100dvh">{renderRoutes(route?.routes)}</Stack>
}

export default AuthLayout
