import { ApolloClient } from '@apollo/client'
import { QueryClient } from '@tanstack/react-query'
import { StoreonStore } from 'storeon'

import WebSocketLink from './Apollo/Links/WebSocket'
import { Store } from './Store'

type Client = ApolloClient<any>
type Services = {
  [SharedService.ApolloClient]?: Client
  [SharedService.QueryClient]?: QueryClient
  [SharedService.Store]?: StoreonStore
  [SharedService.MainSubscriptionLink]?: WebSocketLink
}

export enum SharedService {
  ApolloClient = 'apolloClient',
  QueryClient = 'queryClient',
  Store = 'store',
  MainSubscriptionLink = 'mainSubscriptionLink',
}

const SERVICES: Services = {}

function getService<T = any>(service: SharedService) {
  if (!SERVICES[service]) throw new Error(`${service} not found in Shared`)

  return SERVICES[service] as T
}

function setApolloClient(client: ApolloClient<any>) {
  SERVICES[SharedService.ApolloClient] = client
}

function getApolloClient() {
  return getService<ApolloClient<any>>(SharedService.ApolloClient)
}

function setQueryClient(client: QueryClient) {
  SERVICES[SharedService.QueryClient] = client
}

function getQueryClient() {
  return getService<Store>(SharedService.QueryClient)
}

function setStore(store: Store) {
  SERVICES[SharedService.Store] = store
}

function getStore() {
  return getService<Store>(SharedService.Store)
}

function setMainSubscriptionLink(link: WebSocketLink) {
  SERVICES[SharedService.MainSubscriptionLink] = link
}

function getMainSubscriptionLink() {
  return getService<WebSocketLink>(SharedService.MainSubscriptionLink)
}

export default {
  setApolloClient,
  getApolloClient,
  setStore,
  getStore,
  setQueryClient,
  getQueryClient,
  setMainSubscriptionLink,
  getMainSubscriptionLink,
}
