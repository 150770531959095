import { Components, Theme } from '@mui/material'

import { LinkBehavior } from 'Components/Blocks'

const MuiListItemButton: Components<Theme>['MuiListItemButton'] = {
  defaultProps: {
    LinkComponent: LinkBehavior,
  },
}

export default MuiListItemButton
