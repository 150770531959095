import React, { useCallback } from 'react'
import { useHistory } from 'react-router'

import { Button, Stack, Typography } from '@mui/material'

import { AuthHeader } from 'Components/Blocks'

import * as paths from 'Constants/paths'

function ContactUsPage() {
  const history = useHistory()

  const handleClose = useCallback(() => history.replace(paths.ROOT), [history])

  return (
    <Stack flexGrow={1}>
      <AuthHeader closeable onClose={handleClose} />

      <Stack flexGrow={1} justifyContent="center" px={4}>
        <Typography align="center" variant="h5">
          Thank you!
        </Typography>

        <Typography align="center" color="text.secondary" mt={1.5}>
          Thank you for your enquiry, we will get back to you as soon as
          possible
        </Typography>

        <Button sx={{ mt: 4 }} variant="contained" onClick={handleClose}>
          Close
        </Button>
      </Stack>
    </Stack>
  )
}

export default ContactUsPage
