import * as paths from 'Constants/paths'

import Shared from 'Services/Shared'
import { set as setSession } from 'Services/Store/session'

import { STORE_CLEAR } from './Store'

async function signIn(payload: {
  accessToken?: string
  refreshToken?: string | null
}) {
  setSession(payload)
}

async function logOut() {
  const store = Shared.getStore()
  store.dispatch(STORE_CLEAR)

  const client = Shared.getApolloClient()
  client.stop()
  await client.resetStore()

  // TODO: disconnect subscription links and remove their tokens

  window.location.replace(paths.AUTH)
}

export default {
  signIn,
  logOut,
}
