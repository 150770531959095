import React, { useCallback, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { RouteComponentProps, useHistory } from 'react-router'

import { useMutation } from '@apollo/client'
import { zodResolver } from '@hookform/resolvers/zod'
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material'
import {
  Button,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { enqueueSnackbar } from 'notistack'
import Utils from 'Utils'
import { z } from 'zod'

import { AuthHeader } from 'Components/Blocks'

import * as paths from 'Constants/paths'
import { PASSWORD_REGEXP } from 'Constants/regexps'

import { ChangeEmailPasswordDocument } from 'GraphQL/Main/TypedDocuments'

import { ResetPasswordPageParam } from 'Interfaces/Enums'

enum Fields {
  Password = 'password',
  ConfirmPassword = 'confirmPassword',
}

type Values = z.infer<typeof schema>

const schema = z
  .object({
    [Fields.Password]: z
      .string()
      .min(1, { message: 'Password is required' })
      .min(8, { message: 'Password should have at least 8 characters' })
      .regex(PASSWORD_REGEXP, "Password requirements weren't met"),
    [Fields.ConfirmPassword]: z
      .string()
      .min(1, { message: 'Password is required' })
      .min(8, { message: 'Password should have at least 8 characters' })
      .regex(PASSWORD_REGEXP, "Password requirements weren't met"),
  })
  .refine(data => data[Fields.Password] === data[Fields.ConfirmPassword], {
    message: "Passwords don't match",
    path: [Fields.ConfirmPassword],
  })

function ResetPasswordPage({ location }: RouteComponentProps) {
  const [showPassword, setShowPassword] = useState(false)

  const history = useHistory()

  const params = useMemo(() => {
    const searchParams = new URLSearchParams(location.search)

    return {
      token: searchParams.get(ResetPasswordPageParam.Token),
    }
  }, [location])

  const [changeEmailPassword] = useMutation(ChangeEmailPasswordDocument)

  const { control, handleSubmit } = useForm<Values>({
    resolver: zodResolver(schema),
    defaultValues: {
      [Fields.Password]: '',
      [Fields.ConfirmPassword]: '',
    },
  })

  const handlePasswordIconClick = useCallback(
    () => setShowPassword(show => !show),
    [],
  )

  const onSubmit = useCallback(
    async (values: Values) => {
      if (!params.token) return

      try {
        const response = await changeEmailPassword({
          variables: {
            password: values[Fields.Password],
            token: params.token,
          },
        })

        if (response.data?.changeEmailPassword.ok) {
          history.replace(paths.ROOT)

          enqueueSnackbar(
            'Your password has been successfully changed. Now you can log in using new password',
            { variant: 'success' },
          )
        }
      } catch (error) {
        const [graphQLError] = Utils.Errors.getGraphQLErrors(error)
        enqueueSnackbar(graphQLError, { variant: 'error' })
      }
    },
    [params, history, changeEmailPassword],
  )

  return (
    <Stack flexGrow={1}>
      <AuthHeader />

      <Stack flexGrow={1} px={5}>
        <Typography align="center" variant="h5">
          Create new password
        </Typography>

        <Typography align="center" color="text.secondary" mt={1.5}>
          Please enter and confirm your new password. You&apos;ll need to login
          after reset
        </Typography>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack mt={4} spacing={5}>
            <Stack spacing={2.5}>
              <Controller
                control={control}
                name={Fields.Password}
                render={({ field: { value, onChange }, fieldState }) => (
                  <TextField
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            onClick={handlePasswordIconClick}
                          >
                            {showPassword ? (
                              <VisibilityOffOutlined />
                            ) : (
                              <VisibilityOutlined />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    autoComplete="true"
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error?.message ??
                      'Must be at least 8 characters with 1 uppercase letter, 1 symbol and 1 number'
                    }
                    label="Password"
                    type={showPassword ? 'text' : 'password'}
                    value={value}
                    variant="standard"
                    onChange={onChange}
                  />
                )}
              />

              <Controller
                control={control}
                name={Fields.ConfirmPassword}
                render={({ field: { value, onChange }, fieldState }) => (
                  <TextField
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            onClick={handlePasswordIconClick}
                          >
                            {showPassword ? (
                              <VisibilityOffOutlined />
                            ) : (
                              <VisibilityOutlined />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    autoComplete="true"
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    label="Confirm password"
                    type={showPassword ? 'text' : 'password'}
                    value={value}
                    variant="standard"
                    onChange={onChange}
                  />
                )}
              />
            </Stack>

            <Button size="large" type="submit" variant="contained">
              Save new password
            </Button>
          </Stack>
        </form>
      </Stack>
    </Stack>
  )
}

export default ResetPasswordPage
