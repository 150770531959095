import React from 'react'
import { useLocation } from 'react-router'
import { renderRoutes, RouteConfig } from 'react-router-config'

import {
  BookmarkBorder,
  Explore,
  FlashOn,
  Memory,
  Menu,
} from '@mui/icons-material'
import { BottomNavigationAction, Stack } from '@mui/material'

import * as paths from 'Constants/paths'

import { SafeAreaView } from 'Services/SafeArea'

import { StyledBottomNavigation } from './styles'

type Props = {
  route?: RouteConfig
}

function MainLayout({ route }: Props) {
  const { pathname } = useLocation()

  return (
    <Stack height="100dvh">
      <Stack flexGrow={1} overflow="auto">
        {renderRoutes(route?.routes)}
      </Stack>

      <SafeAreaView bgcolor="background.paper" bottom>
        <StyledBottomNavigation showLabels value={pathname}>
          <BottomNavigationAction
            href={paths.FEED}
            icon={<Explore />}
            label="Feed"
            value={pathname.includes(paths.FEED) ? pathname : paths.FEED}
          />
          <BottomNavigationAction
            href={paths.INSIGHTS}
            icon={<FlashOn />}
            label="Insights"
            value={
              pathname.includes(paths.INSIGHTS) ? pathname : paths.INSIGHTS
            }
          />
          <BottomNavigationAction
            href={paths.AI}
            icon={<Memory />}
            label="AI"
            value={pathname.includes(paths.AI) ? pathname : paths.AI}
          />
          <BottomNavigationAction
            href={paths.BOOKMARKS}
            icon={<BookmarkBorder />}
            label="Bookmarks"
            value={
              pathname.includes(paths.BOOKMARKS) ? pathname : paths.BOOKMARKS
            }
          />
          <BottomNavigationAction
            href={paths.MENU}
            icon={<Menu />}
            label="More"
            value={pathname.includes(paths.MENU) ? pathname : paths.MENU}
          />
        </StyledBottomNavigation>
      </SafeAreaView>
    </Stack>
  )
}

export default MainLayout
