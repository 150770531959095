import React, { useCallback } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { zodResolver } from '@hookform/resolvers/zod'
import { LoadingButton } from '@mui/lab'
import { Stack, Typography } from '@mui/material'
import { MuiOtpInput } from 'mui-one-time-password-input'
import { z } from 'zod'

import { AuthHeader } from 'Components/Blocks'

import { useApp } from 'Services/Store/app'

enum Fields {
  Code = 'code',
}

type Values = z.infer<typeof schema>

const CODE_LENGTH = 6

const schema = z.object({
  [Fields.Code]: z.string().length(CODE_LENGTH),
})

function AccessRequestorPage() {
  const { setPassword } = useApp()

  const {
    control,
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<Values>({
    resolver: zodResolver(schema),
    defaultValues: {
      [Fields.Code]: '',
    },
  })

  const onSubmit = useCallback(
    (values: Values) => {
      setPassword(values[Fields.Code])
      reset()
    },
    [setPassword, reset],
  )

  return (
    <Stack flexGrow={1}>
      <AuthHeader canGoBack={false} />

      <Stack flexGrow={1} px={4}>
        <Typography align="center" variant="h5">
          Please enter the app&apos;s password
        </Typography>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack mt={4} spacing={5}>
            <Controller
              control={control}
              name={Fields.Code}
              render={({ field: { value, onChange } }) => (
                <MuiOtpInput
                  TextFieldsProps={{
                    type: 'number',
                  }}
                  justifyContent="center"
                  length={CODE_LENGTH}
                  value={value}
                  onChange={onChange}
                />
              )}
            />

            <LoadingButton
              loading={isSubmitting}
              size="large"
              type="submit"
              variant="contained"
            >
              Submit code
            </LoadingButton>
          </Stack>
        </form>
      </Stack>
    </Stack>
  )
}

export default AccessRequestorPage
