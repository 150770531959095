import React, { useCallback, useMemo, useState } from 'react'

import { useMutation } from '@apollo/client'
import { BookmarkBorder, Favorite, ShareOutlined } from '@mui/icons-material'
import { Button, Link, Stack, Typography } from '@mui/material'
import { PaddingProps } from '@mui/system'
import { DateTime } from 'luxon'
import Image from 'mui-image'
import { enqueueSnackbar } from 'notistack'
import numbro from 'numbro'
import Utils from 'Utils'

import Markdown from 'Components/Blocks/Markdown'

import * as paths from 'Constants/paths'
import { BOOKMARK_LIST_LIMIT } from 'Constants/queriesVariables'

import { ArticleRecordFragment } from 'GraphQL/DatoCMS/TypedDocuments'
import ArticleRecordUpdater from 'GraphQL/DatoCMS/Updaters/ArticleRecord'
import {
  AddBookmarkDocument,
  AddLikeDocument,
  AddShareDocument,
  BookmarksDocument,
  DatoModelKind,
  RemoveBookmarkDocument,
  RemoveLikeDocument,
} from 'GraphQL/Main/TypedDocuments'

import { useAppContext } from 'Services/AppContext'

import Publisher from './Publisher'
import { ActionButton } from './styles'

type Props = PaddingProps & {
  item: ArticleRecordFragment
}

const TEXT_CROPPED_LENGTH = 300
const COUNT_FORMAT_CONFIG = {
  spaceSeparated: false,
  average: true,
  mantissa: 2,
  optionalMantissa: true,
}

function ArticleItem({ item, ...rest }: Props) {
  const [showMore, setShowMore] = useState(false)

  const { viewer } = useAppContext()

  const [addBookmark] = useMutation(AddBookmarkDocument, {
    // TODO: We don't know what id bookmark has to remove it from the bookmark list
    refetchQueries: [
      { query: BookmarksDocument, variables: { limit: BOOKMARK_LIST_LIMIT } },
    ],
    update: (cache, data) => {
      if (!viewer || !data.data?.addBookmark) return

      ArticleRecordUpdater.addBookmark({
        cache,
        id: item.id,
        viewerId: viewer.id,
      })
    },
  })
  const [removeBookmark] = useMutation(RemoveBookmarkDocument, {
    // TODO: We don't know what id bookmark has to remove it from the bookmark list
    refetchQueries: [
      { query: BookmarksDocument, variables: { limit: BOOKMARK_LIST_LIMIT } },
    ],
    update: (cache, data) => {
      if (!viewer || !data.data?.removeBookmark) return

      ArticleRecordUpdater.removeBookmark({
        cache,
        id: item.id,
        viewerId: viewer.id,
      })
    },
  })
  const [addLike] = useMutation(AddLikeDocument, {
    update: (cache, data) => {
      if (!viewer || !data.data?.addLike.ok) return

      ArticleRecordUpdater.addLike({
        cache,
        id: item.id,
        viewerId: viewer.id,
      })
    },
  })
  const [removeLike] = useMutation(RemoveLikeDocument, {
    update: (cache, data) => {
      if (!viewer || !data.data?.removeLike.ok) return

      ArticleRecordUpdater.removeLike({
        cache,
        id: item.id,
        viewerId: viewer.id,
      })
    },
  })
  const [addShare] = useMutation(AddShareDocument, {
    update: (cache, data) => {
      if (!viewer || !data.data?.addShare.ok) return

      ArticleRecordUpdater.addShare({
        cache,
        id: item.id,
        viewerId: viewer.id,
      })
    },
  })

  const values = useMemo(() => {
    if (!viewer) return {}

    const text = item.text ?? ''
    const likedBy = item.likedBy ?? {}
    const bookmarkedBy = item.bookmarkedBy ?? {}
    const sharedBy = item.sharedBy ?? {}
    const likeCount = Object.keys(likedBy).length
    const liked = !!likedBy[viewer.id]
    const bookmarkCount = Object.keys(bookmarkedBy).length
    const bookmarked = !!bookmarkedBy[viewer.id]
    const shareCount = Object.keys(sharedBy).length
    const shared = !!sharedBy[viewer.id]
    const sourceLink = item.sourceLink ?? paths.ROOT
    const canShare = 'share' in navigator

    return {
      text,
      liked,
      bookmarked,
      shared,
      hasMoreText: text.length > TEXT_CROPPED_LENGTH,
      likeCount: numbro(likeCount).format(COUNT_FORMAT_CONFIG),
      bookmarkCount: numbro(bookmarkCount).format(COUNT_FORMAT_CONFIG),
      shareCount: numbro(shareCount).format(COUNT_FORMAT_CONFIG),
      date: DateTime.fromISO(
        // eslint-disable-next-line no-underscore-dangle
        item._createdAt,
      ).toLocaleString(DateTime.DATE_MED),
      sourceLink,
      canShare,
    }
  }, [item, viewer])

  const handleBookmarkClick = useCallback(async () => {
    const entityId = item.id

    try {
      if (values.bookmarked) {
        await removeBookmark({ variables: { entityId } })
      } else {
        await addBookmark({
          variables: { entityId, kind: DatoModelKind.Article },
        })
      }
    } catch (error) {
      const [graphQLError] = Utils.Errors.getGraphQLErrors(error)
      enqueueSnackbar(graphQLError, { variant: 'error' })
    }
  }, [item, values, addBookmark, removeBookmark])

  const handleLikeClick = useCallback(async () => {
    const entityId = item.id

    try {
      if (values.liked) {
        await removeLike({ variables: { entityId } })
      } else {
        await addLike({
          variables: { entityId, kind: DatoModelKind.Article },
        })
      }
    } catch (error) {
      const [graphQLError] = Utils.Errors.getGraphQLErrors(error)
      enqueueSnackbar(graphQLError, { variant: 'error' })
    }
  }, [item, values, addLike, removeLike])

  const handleShareClick = useCallback(async () => {
    if (values.shared || !values.sourceLink) return

    try {
      await navigator.share({
        title: item.title ?? 'The Digital Speaker',
        text: 'Check this article',
        url: values.sourceLink,
      })

      await addShare({
        variables: { entityId: item.id, kind: DatoModelKind.Article },
      })
    } catch (error) {
      //
    }
  }, [item, values, addShare])

  return (
    <Stack {...rest} spacing={1.5}>
      <Stack spacing={1}>
        <Link
          color="inherit"
          component="a"
          href={values.sourceLink}
          target="_blank"
        >
          <Typography variant="subtitle1">{item.title}</Typography>
        </Link>

        <Typography color="text.secondary" variant="caption">
          {values.date}
        </Typography>

        <Markdown truncate={!showMore} truncateLimit={TEXT_CROPPED_LENGTH}>
          {values.text}
        </Markdown>

        {values.hasMoreText && !showMore && (
          <Button onClick={() => setShowMore(true)}>read more</Button>
        )}
      </Stack>

      <Link
        color="inherit"
        component="a"
        href={values.sourceLink}
        target="_blank"
      >
        <Stack spacing={1.5}>
          {!!item.imageUrl && (
            <Image
              alt={item.title ?? 'Article'}
              src={item.imageUrl}
              sx={{ aspectRatio: 2, borderRadius: 3 }}
            />
          )}

          <Publisher article={item} />
        </Stack>
      </Link>

      {/* {!!item.sourceLink && (
        <Link
          color="inherit"
          component="a"
          href={values.sourceLink}
          target="_blank"
        >
          <LinkPreview url={item.sourceLink} />
        </Link>
      )} */}

      <Stack direction="row">
        <ActionButton
          active={values.liked}
          startIcon={<Favorite />}
          onClick={handleLikeClick}
        >
          {values.likeCount}
        </ActionButton>

        <ActionButton
          active={values.bookmarked}
          startIcon={<BookmarkBorder />}
          onClick={handleBookmarkClick}
        >
          {values.bookmarkCount}
        </ActionButton>

        {values.canShare && (
          <ActionButton
            active={values.shared}
            startIcon={<ShareOutlined />}
            onClick={handleShareClick}
          >
            {values.shareCount}
          </ActionButton>
        )}
      </Stack>
    </Stack>
  )
}

export default ArticleItem
