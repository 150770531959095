import { useMemo } from 'react'

import { jwtDecode } from 'jwt-decode'
import { StoreonModule } from 'storeon'
import { useStoreon } from 'storeon/react'

import Shared from 'Services/Shared'

const KEY = 'session'

interface StateValues {
  accessToken?: string
  refreshToken?: string | null
}

interface State {
  [KEY]: StateValues
}

interface Events {
  'session/set': StateValues
  'session/clear': void
}

export const INITIAL_STATE: StateValues = {
  accessToken: undefined,
  refreshToken: undefined,
}

const module: StoreonModule<State, Events> = store => {
  store.on('@init', () => ({
    [KEY]: INITIAL_STATE,
  }))
  store.on('session/set', (state, payload) => {
    const prevSession = state[KEY]

    return {
      [KEY]: {
        ...prevSession,
        ...payload,
      },
    }
  })
  store.on('session/clear', () => ({
    [KEY]: INITIAL_STATE,
  }))
}

function set(payload: StateValues) {
  Shared.getStore()?.dispatch?.('session/set', payload)
}

function clear() {
  Shared.getStore()?.dispatch?.('session/clear')
}

function getState(): StateValues {
  return Shared.getStore()?.get()?.[KEY]
}

function getAccessToken() {
  return getState()?.accessToken
}

function getRefreshToken() {
  return getState()?.refreshToken
}

function useSession() {
  const { [KEY]: state } = useStoreon<State>(KEY)

  return useMemo(() => {
    let { accessToken } = state

    try {
      if (state.accessToken) {
        jwtDecode(state.accessToken)
      }
    } catch (error) {
      accessToken = undefined
    }

    return { accessToken, authenticated: !!accessToken }
  }, [state])
}

export {
  clear,
  Events,
  getAccessToken,
  getRefreshToken,
  KEY,
  module,
  set,
  State,
  useSession,
}
