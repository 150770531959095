import { createTheme } from '@mui/material'
import Color from 'color'

import merge from 'lodash/merge'

import { MuiThemeOptions as DefaultMuiThemeOptions } from 'Theme/Default/MuiTheme'

const MuiThemeOptions = merge({}, DefaultMuiThemeOptions, {
  palette: {
    mode: 'dark',

    primary: {
      main: '#3AF5E3',
      dark: '#2CE8D5',
      light: '#51F6E6',
    },
    background: {
      default: '#152535',
      paper: '#1C2E40',
    },
    text: {
      primary: '#E4E6E7',
      secondary: '#CCCCCC',
      disabled: '#52647A',
    },
    divider: Color('#ffffff').alpha(0.06).string(),
  },
} as const)

const MuiTheme = createTheme(MuiThemeOptions)

export default MuiTheme
