import React, { useCallback, useMemo } from 'react'
import { useParams } from 'react-router'

import { useMutation, useQuery } from '@apollo/client'
import { BookmarkBorder, Favorite, ShareOutlined } from '@mui/icons-material'
import { Stack } from '@mui/material'
import numbro from 'numbro'

import { Browser, TitleHeader } from 'Components/Blocks'

import * as paths from 'Constants/paths'
import { BOOKMARK_LIST_LIMIT } from 'Constants/queriesVariables'

import {
  ArticleRecordFragment,
  DatoCmsArticleDocument,
} from 'GraphQL/DatoCMS/TypedDocuments'
import ArticleRecordUpdater from 'GraphQL/DatoCMS/Updaters/ArticleRecord'
import InsightRecordUpdater from 'GraphQL/DatoCMS/Updaters/InsightRecord'
import {
  AddBookmarkDocument,
  AddLikeDocument,
  AddShareDocument,
  BookmarksDocument,
  DatoModelKind,
  RemoveBookmarkDocument,
  RemoveLikeDocument,
} from 'GraphQL/Main/TypedDocuments'

import { useAppContext } from 'Services/AppContext'
import { SafeAreaView } from 'Services/SafeArea'

import { ActionButton } from './styles'

type RouteParams = {
  articleId?: ArticleRecordFragment['id']
}

const COUNT_FORMAT_CONFIG = {
  spaceSeparated: false,
  average: true,
  mantissa: 2,
  optionalMantissa: true,
}

function ArticlePage() {
  const { articleId } = useParams<RouteParams>()

  const { viewer } = useAppContext()

  const { data } = useQuery(DatoCmsArticleDocument, {
    variables: { filter: { id: { eq: articleId } } },
    skip: !articleId,
  })
  const article = data?.article

  const values = useMemo(() => {
    if (!viewer || !article) return {}

    const likedBy = article.likedBy ?? {}
    const bookmarkedBy = article.bookmarkedBy ?? {}
    const sharedBy = article.sharedBy ?? {}
    const likeCount = Object.keys(likedBy).length
    const liked = !!likedBy[viewer.id]
    const bookmarkCount = Object.keys(bookmarkedBy).length
    const bookmarked = !!bookmarkedBy[viewer.id]
    const shareCount = Object.keys(sharedBy).length
    const shared = !!sharedBy[viewer.id]
    const sourceLink = article.sourceLink ?? paths.ROOT
    const canShare = 'share' in navigator

    return {
      liked,
      bookmarked,
      shared,
      likeCount: numbro(likeCount).format(COUNT_FORMAT_CONFIG),
      bookmarkCount: numbro(bookmarkCount).format(COUNT_FORMAT_CONFIG),
      shareCount: numbro(shareCount).format(COUNT_FORMAT_CONFIG),
      // eslint-disable-next-line no-underscore-dangle
      sourceLink,
      canShare,
    }
  }, [article, viewer])

  const [addBookmark] = useMutation(AddBookmarkDocument, {
    // TODO: We don't know what id bookmark has to remove it from the bookmark list
    refetchQueries: [
      { query: BookmarksDocument, variables: { limit: BOOKMARK_LIST_LIMIT } },
    ],
    update: (cache, data) => {
      if (!viewer || !data.data?.addBookmark) return

      ArticleRecordUpdater.addBookmark({
        cache,
        id: articleId,
        viewerId: viewer.id,
      })
    },
  })
  const [removeBookmark] = useMutation(RemoveBookmarkDocument, {
    // TODO: We don't know what id bookmark has to remove it from the bookmark list
    refetchQueries: [
      { query: BookmarksDocument, variables: { limit: BOOKMARK_LIST_LIMIT } },
    ],
    update: (cache, data) => {
      if (!viewer || !data.data?.removeBookmark) return

      ArticleRecordUpdater.removeBookmark({
        cache,
        id: articleId,
        viewerId: viewer.id,
      })
    },
  })
  const [addLike] = useMutation(AddLikeDocument, {
    update: (cache, data) => {
      if (!viewer || !data.data?.addLike.ok) return

      ArticleRecordUpdater.addLike({
        cache,
        id: articleId,
        viewerId: viewer.id,
      })
    },
  })
  const [removeLike] = useMutation(RemoveLikeDocument, {
    update: (cache, data) => {
      if (!viewer || !data.data?.removeLike.ok) return

      ArticleRecordUpdater.removeLike({
        cache,
        id: articleId,
        viewerId: viewer.id,
      })
    },
  })
  const [addShare] = useMutation(AddShareDocument, {
    update: (cache, data) => {
      if (!viewer || !data.data?.addShare.ok) return

      InsightRecordUpdater.addShare({
        cache,
        id: articleId,
        viewerId: viewer.id,
      })
    },
  })

  const handleLikeClick = useCallback(async () => {
    try {
      if (values.liked) {
        await removeLike({ variables: { entityId: articleId } })
      } else {
        await addLike({
          variables: { entityId: articleId, kind: DatoModelKind.Article },
        })
      }
    } catch (error) {
      //
    }
  }, [articleId, values, addLike, removeLike])

  const handleBookmarkClick = useCallback(async () => {
    try {
      if (values.bookmarked) {
        await removeBookmark({ variables: { entityId: articleId } })
      } else {
        await addBookmark({
          variables: { entityId: articleId, kind: DatoModelKind.Article },
        })
      }
    } catch (error) {
      //
    }
  }, [articleId, values, addBookmark, removeBookmark])

  const handleShareClick = useCallback(async () => {
    if (values.shared || !article) return

    try {
      await navigator.share({
        title: article.title ?? 'The Digital Speaker',
        text: 'Check this article',
        url: window.location.href,
      })

      await addShare({
        variables: { entityId: article.id, kind: DatoModelKind.Article },
      })
    } catch (error) {
      //
    }
  }, [article, values, addShare])

  return (
    <Stack flexGrow={1}>
      <TitleHeader title={article?.title ?? ''} />

      <Stack flexGrow={1}>
        {!!values.sourceLink && (
          <Browser src={values.sourceLink} title="Article" />
        )}
      </Stack>

      <SafeAreaView bgcolor="background.paper" bottom>
        <Stack flexDirection="row" p={1}>
          <ActionButton
            active={values.liked}
            startIcon={<Favorite />}
            onClick={handleLikeClick}
          >
            {values.likeCount}
          </ActionButton>

          <ActionButton
            active={values.bookmarked}
            startIcon={<BookmarkBorder />}
            onClick={handleBookmarkClick}
          >
            {values.bookmarkCount}
          </ActionButton>

          {values.canShare && (
            <ActionButton
              active={values.shared}
              startIcon={<ShareOutlined />}
              onClick={handleShareClick}
            >
              {values.shareCount}
            </ActionButton>
          )}
        </Stack>
      </SafeAreaView>
    </Stack>
  )
}

export default ArticlePage
