import { v4 } from 'uuid'

import compact from 'lodash/compact'
import join from 'lodash/join'
import map from 'lodash/map'
import slice from 'lodash/slice'
import split from 'lodash/split'
import trim from 'lodash/trim'

function makeAcronym(value: string, limit = 3) {
  return slice(
    join(
      map(compact(split(value, ' ')), item => item[0].toUpperCase()),
      '',
    ),
    0,
    limit,
  )
}

function singleSpaces(value: string) {
  return value.replace(/  +/g, ' ')
}

function sanitize(value = '') {
  return trim(singleSpaces(value))
}

function uuidv4() {
  return v4()
}

export default {
  makeAcronym,
  sanitize,
  singleSpaces,
  uuidv4,
}
