import { useCallback, useMemo } from 'react'

import { StoreonModule } from 'storeon'
import { useStoreon } from 'storeon/react'

import { APP } from 'Config'

import Shared from 'Services/Shared'

const KEY = 'app'

interface StateValues {
  password?: string
  welcomed?: boolean
  notificationsRequested?: boolean
}

interface State {
  [KEY]: StateValues
}

interface Events {
  'app/setNotificationsRequested': boolean
  'app/setWelcomed': boolean
  'app/setPassword': string
  'app/clear': void
}

const INITIAL_STATE: StateValues = {
  password: undefined,
  welcomed: false,
  notificationsRequested: false,
}

const module: StoreonModule<State, Events> = store => {
  store.on('@init', () => ({
    [KEY]: INITIAL_STATE,
  }))
  store.on('app/setPassword', (state, password) => ({
    [KEY]: {
      ...state[KEY],
      password,
    },
  }))
  store.on(
    'app/setNotificationsRequested',
    (state, notificationsRequested) => ({
      [KEY]: {
        ...state[KEY],
        notificationsRequested,
      },
    }),
  )
  store.on('app/setWelcomed', (state, welcomed) => ({
    [KEY]: {
      ...state[KEY],
      welcomed,
    },
  }))
  store.on('app/clear', () => ({
    [KEY]: INITIAL_STATE,
  }))
}

function isPasswordValid(password?: string) {
  return APP.PASSWORD_REQUIRED ? password === APP.PASSWORD : true
}

function useApp() {
  const {
    [KEY]: { password, welcomed, notificationsRequested },
    dispatch,
  } = useStoreon<State, Events>(KEY)

  const accessGranted = useMemo(() => isPasswordValid(password), [password])

  const setPassword = useCallback(
    (nextPassword: string) => {
      dispatch('app/setPassword', nextPassword)
    },
    [dispatch],
  )
  const setWelcomed = useCallback(
    (nextWelcomed: boolean) => {
      dispatch('app/setWelcomed', nextWelcomed)
    },
    [dispatch],
  )
  const setNotificationsRequested = useCallback(
    (nextNotificationsRequested: boolean) => {
      dispatch('app/setNotificationsRequested', nextNotificationsRequested)
    },
    [dispatch],
  )

  return {
    password,
    welcomed,
    notificationsRequested,
    accessGranted,
    setPassword,
    setWelcomed,
    setNotificationsRequested,
  }
}

function clear() {
  Shared.getStore()?.dispatch?.('app/clear')
}

export { clear, Events, INITIAL_STATE, KEY, module, State, useApp }
