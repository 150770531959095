import {
  ArticleRecordFragment,
  InsightRecordFragment,
  LectureRecordFragment,
} from 'GraphQL/DatoCMS/TypedDocuments'

export const ROOT = '/'
export const REDIRECTOR = `${ROOT}redirector`
export const ACCESS_REQUESTOR = `${ROOT}access`

// Auth
export const AUTH = `${ROOT}auth`
export const SIGN_IN = `${AUTH}/sign-in`
export const SIGN_UP = `${AUTH}/sign-up`
export const REQUEST_RESET_PASSWORD = `${AUTH}/forgot-password`
export const RESET_PASSWORD = `${AUTH}/reset-password`
export const CHANGE_EMAIL_PASSWORD = `${AUTH}/change-email-password`
export const VERIFY_EMAIL = `${AUTH}/verify-email`

// Admin Auth
export const ADMIN_AUTH = `${ROOT}manage`
export const ADMIN_SIGN_IN = `${ADMIN_AUTH}/sign-in`

// Admin
export const ADMIN = `${ROOT}admin`
export const ADMIN_USERS = `${ADMIN}/users`
export const ADMIN_ARTICLES = `${ADMIN}/articles`
export const ADMIN_INSIGHTS = `${ADMIN}/insights`

// Main
export const FEED = `${ROOT}feed`
export const ARTICLE = (id?: ArticleRecordFragment['id']) =>
  `${FEED}/${id || ':articleId'}`
export const INSIGHTS = `${ROOT}insights`
export const INSIGHT = (id?: InsightRecordFragment['id']) =>
  `${INSIGHTS}/${id || ':insightId'}`
export const INSIGHT_BROWSER = (id?: InsightRecordFragment['id']) =>
  `${INSIGHT(id)}/browser`
export const LECTURE = (id?: LectureRecordFragment['id']) =>
  `${ROOT}lectures/${id || ':lectureId'}`
export const LECTURE_QUIZ = (id?: LectureRecordFragment['id']) =>
  `${LECTURE(id)}/quiz`
export const AI = `${ROOT}ai`
export const AI_TEXT = `${AI}/text`
export const AI_AUDIO = `${AI}/audio`
export const AI_VIDEO = `${AI}/video`
export const BOOKMARKS = `${ROOT}bookmarks`
export const MENU = `${ROOT}menu`
export const PROFILE = `${ROOT}profile`
export const PROFILE_UPDATE = `${PROFILE}/update`
export const SUBSCRIPTION = `${ROOT}subscription`
export const SUBSCRIPTION_USAGE = `${SUBSCRIPTION}/usage`
export const SUBSCRIPTION_SUCCESS = `${ROOT}subscription/success`
export const SUBSCRIPTION_CANCELED = `${ROOT}subscription/canceled`
export const CONTACT_US = `${ROOT}contact-us`
export const CONTACT_US_SUCCESS = `${CONTACT_US}/success`
export const NOTIFICATIONS = `${ROOT}notifications`
export const WELCOME = `${ROOT}welcome`

export const EXTERNAL_MASTERCLASS = `${ROOT}masterclass`

// Legal
export const LEGAL = `${ROOT}legal`
export const FAQ = `${LEGAL}/faq`
export const PRIVACY_POLICY = `${LEGAL}/privacy-policy`
export const TERMS_OF_SERVICE = `${LEGAL}/terms-of-service`
