import React, { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router'

import {
  BorderColor,
  ChatBubbleOutline,
  ChevronRight,
  DarkModeOutlined,
  HelpOutline,
  InfoOutlined,
  Key,
  Logout,
  NotificationsNone,
  ShieldOutlined,
} from '@mui/icons-material'
import {
  Avatar,
  Button,
  Chip,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Switch,
  SwitchProps,
  Typography,
} from '@mui/material'
import Utils from 'Utils'

import * as paths from 'Constants/paths'

import { SubscriptionPlanKind } from 'GraphQL/Main/TypedDocuments'

import { useAppContext } from 'Services/AppContext'
import Auth from 'Services/Auth'
import { SafeAreaView } from 'Services/SafeArea'
import { useUI } from 'Services/Store/ui'

import { ThemeID } from 'Theme'

import { UserInfoContainer } from './styles'

function MenuPage() {
  const { viewer } = useAppContext()
  const { theme, setThemeId } = useUI()
  const history = useHistory()

  const values = useMemo(() => {
    const planLabel = (() => {
      switch (viewer?.userSubscription?.planKind) {
        case SubscriptionPlanKind.Paid:
          return 'STARTER plan'
        case SubscriptionPlanKind.Expert:
          return 'EXPERT plan'
        default:
          return 'FREE plan'
      }
    })()

    return {
      planLabel,
      fullName: Utils.User.formatFullName(viewer),
      photoUrl: viewer?.profile?.photoUrl ?? '',
      email: viewer?.primaryEmailCredential?.email ?? '',
    }
  }, [viewer])

  const handleThemeSwitchChange = useCallback<
    NonNullable<SwitchProps['onChange']>
  >(
    (event, checked) => {
      setThemeId(checked ? ThemeID.Dark : ThemeID.Default)
    },
    [setThemeId],
  )

  const handlePlanClick = useCallback(() => {
    history.push(paths.SUBSCRIPTION)
  }, [history])

  const handleLogoutClock = useCallback(() => {
    Auth.logOut().then()
  }, [])

  return (
    <Stack flexGrow={1} p={2}>
      <SafeAreaView top />

      <UserInfoContainer p={2} spacing={2}>
        <Stack direction="row" justifyContent="space-between">
          <Avatar
            alt={values.fullName}
            src={values.photoUrl}
            sx={{ width: 56, height: 56 }}
          />

          <Chip clickable label={values.planLabel} onClick={handlePlanClick} />
        </Stack>

        <Stack spacing={0.5}>
          <Typography variant="h6">{values.fullName}</Typography>

          {!!values.email && (
            <Typography color="text.secondary" variant="caption">
              {values.email}
            </Typography>
          )}
        </Stack>

        <Stack
          color="text.secondary"
          direction="row"
          justifyContent="space-between"
        >
          <Button
            color="inherit"
            href={paths.PROFILE_UPDATE}
            startIcon={<BorderColor />}
          >
            Edit profile
          </Button>

          <Button
            color="error"
            endIcon={<Logout />}
            onClick={handleLogoutClock}
          >
            Log out
          </Button>
        </Stack>
      </UserInfoContainer>

      <Stack flexGrow={1} justifyContent="space-between">
        <List>
          {/* <ListItemButton>
            <ListItemIcon sx={{ minWidth: 40 }}>
              <EmailOutlined />
            </ListItemIcon>

            <ListItemText primary="Change Email" />
            <ChevronRight />
          </ListItemButton> */}

          <ListItemButton href={paths.REQUEST_RESET_PASSWORD}>
            <ListItemIcon sx={{ minWidth: 40 }}>
              <Key />
            </ListItemIcon>

            <ListItemText primary="Change Password" />
            <ChevronRight />
          </ListItemButton>

          <ListItemButton href={paths.NOTIFICATIONS}>
            <ListItemIcon sx={{ minWidth: 40 }}>
              <NotificationsNone />
            </ListItemIcon>

            <ListItemText primary="Notifications" />
            <ChevronRight />
          </ListItemButton>

          <ListItemButton href={paths.FAQ}>
            <ListItemIcon sx={{ minWidth: 40 }}>
              <HelpOutline />
            </ListItemIcon>

            <ListItemText primary="FAQ" />
            <ChevronRight />
          </ListItemButton>

          <ListItemButton href={paths.CONTACT_US}>
            <ListItemIcon sx={{ minWidth: 40 }}>
              <ChatBubbleOutline />
            </ListItemIcon>

            <ListItemText primary="Contact" />
            <ChevronRight />
          </ListItemButton>

          <ListItemButton href={paths.PRIVACY_POLICY}>
            <ListItemIcon sx={{ minWidth: 40 }}>
              <ShieldOutlined />
            </ListItemIcon>

            <ListItemText primary="Privacy Policy" />
            <ChevronRight />
          </ListItemButton>

          <ListItemButton href={paths.TERMS_OF_SERVICE}>
            <ListItemIcon sx={{ minWidth: 40 }}>
              <InfoOutlined />
            </ListItemIcon>

            <ListItemText primary="Terms & Conditions" />
            <ChevronRight />
          </ListItemButton>
        </List>

        <List>
          <ListItem>
            <ListItemIcon sx={{ minWidth: 40 }}>
              <DarkModeOutlined />
            </ListItemIcon>

            <ListItemText primary="Dark theme" />

            <Switch
              checked={theme.dark}
              edge="end"
              onChange={handleThemeSwitchChange}
            />
          </ListItem>
        </List>
      </Stack>
    </Stack>
  )
}

export default MenuPage
