import { ApolloLink } from '@apollo/client'

import { DATOCMS } from 'Config'

import { getAccessToken } from 'Services/Store/session'

import { isDatoCMSOperation } from './utils'

export default function createAuthLink() {
  return new ApolloLink((operation, forward) => {
    operation.setContext(
      (prevContext: Record<string, Record<string, unknown>>) => {
        const accessToken = isDatoCMSOperation(operation)
          ? DATOCMS.API_TOKEN
          : getAccessToken()

        return {
          headers: {
            ...prevContext.headers,
            Authorization: accessToken ? `Bearer ${accessToken}` : null,
          },
        }
      },
    )

    return forward(operation)
  })
}
