import { createTheme } from '@mui/material'
import Color from 'color'

import MuiBottomNavigationAction from './MuiBottomNavigationAction'
import MuiButton from './MuiButton'
import MuiButtonBase from './MuiButtonBase'
import MuiFormHelperText from './MuiFormHelperText'
import MuiLink from './MuiLink'
import MuiListItemButton from './MuiListItemButton'

export const MuiThemeOptions = {
  palette: {
    primary: {
      main: '#573DF5',
      dark: '#4B30E8',
      light: '#6C55F6',
    },
    background: {
      default: '#FCFCFD',
    },
    text: {
      primary: '#162535',
      secondary: Color('#162535').alpha(0.75).string(),
      disabled: Color('#162535').alpha(0.38).string(),
    },
    divider: Color('#000000').alpha(0.06).string(),
  },

  typography: {
    fontFamily: ['Work Sans', 'BlinkMacSystemFont', 'sans-serif'].join(','),

    h1: {
      fontWeight: 400,
    },
    h2: {
      fontWeight: 500,
    },
    h3: {
      fontWeight: 500,
    },
    h4: {
      fontWeight: 500,
    },
    h5: {
      fontWeight: 600,
    },
    h6: {
      fontWeight: 600,
      lineHeight: 1.2,
    },
    subtitle1: {
      fontWeight: 500,
      lineHeight: 1.35,
    },
    caption: {
      fontWeight: 500,
      lineHeight: 1.35,
    },
    overline: {
      fontWeight: 600,
      lineHeight: 1.35,
    },
  },

  components: {
    MuiBottomNavigationAction,
    MuiButton,
    MuiButtonBase,
    MuiFormHelperText,
    MuiLink,
    MuiListItemButton,
  },
}

const MuiTheme = createTheme(MuiThemeOptions)

export default MuiTheme
