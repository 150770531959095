import React, { DetailedHTMLProps } from 'react'

import { Typography } from '@mui/material'

type MarkdownComponentProps = DetailedHTMLProps<any, any>

function Strong(props: MarkdownComponentProps) {
  return (
    <Typography
      {...props}
      color="text.primary"
      component="span"
      variant="subtitle2"
    />
  )
}

function P(props: MarkdownComponentProps) {
  return <Typography {...props} color="text.secondary" variant="body2" />
}

function Code(props: MarkdownComponentProps) {
  return <Typography {...props} fontStyle="italic" variant="body2" />
}

export { Code, P, Strong }
