import Billing from './billing'
import Errors from './errors'
import String from './string'
import User from './user'

export default {
  Billing,
  Errors,
  String,
  User,
}
