import { useCallback, useMemo } from 'react'

import { StoreonModule } from 'storeon'
import { useStoreon } from 'storeon/react'

import { DEFAULT_THEME_ID, ThemeID, THEMES } from 'Theme'

const KEY = 'ui'

interface StateValues {
  themeId: ThemeID
}

interface State {
  [KEY]: StateValues
}

interface Events {
  'ui/setThemeId': ThemeID
  'ui/clear': void
}

const INITIAL_STATE: StateValues = {
  themeId: DEFAULT_THEME_ID,
}

const module: StoreonModule<State, Events> = store => {
  store.on('@init', () => ({
    [KEY]: INITIAL_STATE,
  }))
  store.on('ui/setThemeId', (state, themeId) => ({
    [KEY]: {
      ...state[KEY],
      themeId,
    },
  }))
  store.on('ui/clear', () => ({
    [KEY]: INITIAL_STATE,
  }))
}

function useUI() {
  const {
    [KEY]: { themeId },
    dispatch,
  } = useStoreon<State, Events>(KEY)

  const theme = useMemo(
    () => THEMES[themeId] ?? THEMES[DEFAULT_THEME_ID],
    [themeId],
  )

  const setThemeId = useCallback(
    (id: ThemeID) => {
      dispatch('ui/setThemeId', id)
    },
    [dispatch],
  )

  return { theme, themeId, setThemeId }
}

export { Events, KEY, module, State, useUI }
