import React, { useMemo } from 'react'

import { AccessTime, InfoOutlined, ShowChart } from '@mui/icons-material'
import { Divider, Stack, Typography } from '@mui/material'
import { SystemProps } from '@mui/system'
import numbro from 'numbro'

import { Markdown } from 'Components/Blocks'

import { InsightRecordFragment } from 'GraphQL/DatoCMS/TypedDocuments'

const COUNT_FORMAT_CONFIG = {
  spaceSeparated: false,
  average: true,
  mantissa: 1,
  optionalMantissa: true,
}

type Props = SystemProps & {
  insight: InsightRecordFragment
}

function OverviewTab({ insight, ...rest }: Props) {
  const values = useMemo(() => {
    return {
      duration: numbro(insight.hoursLength ?? 0).format(COUNT_FORMAT_CONFIG),
      complexityLevel: insight.complexityLevel,
    }
  }, [insight])

  return (
    <Stack {...rest} overflow="auto" spacing={2}>
      <Stack alignItems="center" direction="row" spacing={1.5}>
        <AccessTime sx={{ fontSize: 16, color: 'text.secondary' }} />
        <Typography variant="subtitle2">Duration</Typography>
        <Divider sx={{ flexGrow: 1, pt: 1.5 }} />
        <Typography color="text.secondary" variant="body2">
          {values.duration} hours
        </Typography>
      </Stack>

      <Stack alignItems="center" direction="row" spacing={1.5}>
        <ShowChart sx={{ fontSize: 16, color: 'text.secondary' }} />
        <Typography variant="subtitle2">Level</Typography>
        <Divider sx={{ flexGrow: 1, pt: 1.5 }} />
        <Typography color="text.secondary" variant="body2">
          {values.complexityLevel}
        </Typography>
      </Stack>

      <Stack spacing={1}>
        <Stack alignItems="center" direction="row" spacing={1.5}>
          <InfoOutlined sx={{ fontSize: 16, color: 'text.secondary' }} />
          <Typography variant="subtitle2">What will I learn?</Typography>
        </Stack>

        <Markdown>{insight.text}</Markdown>
      </Stack>
    </Stack>
  )
}

export default OverviewTab
