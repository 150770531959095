import {
  FieldFunctionOptions,
  FieldPolicy,
  Reference,
  TypePolicies,
} from '@apollo/client'

import {
  DatoCmsAllArticlesQuery,
  DatoCmsAllArticlesQueryVariables,
  DatoCmsAllInsightsQuery,
  DatoCmsAllInsightsQueryVariables,
} from 'GraphQL/DatoCMS/TypedDocuments'
import {
  BookmarksQuery,
  BookmarksQueryVariables,
} from 'GraphQL/Main/TypedDocuments'

type ConcreteFieldPolicy<
  TResult,
  TArgs extends Record<string, any>,
> = FieldPolicy<TResult, TResult, TResult, FieldFunctionOptions<TArgs>>

type ReferenceCursorList<T extends { rows: any[] }> = Omit<T, 'rows'> & {
  rows: Reference[]
}

export default {
  Query: {
    fields: {
      bookmarks: {
        merge(existing, incoming) {
          const existingRows = existing?.rows ?? []
          const existingRowsSet = new Set(
            // eslint-disable-next-line no-underscore-dangle
            existingRows.map((row: any) => row.__ref),
          )
          const incomingRows =
            incoming?.rows?.filter(
              (incomingRow: any) =>
                // eslint-disable-next-line no-underscore-dangle
                !existingRowsSet.has(incomingRow.__ref),
            ) ?? []

          return {
            ...existing,
            ...incoming,
            rows: [...existingRows, ...incomingRows],
          }
        },
      } as ConcreteFieldPolicy<
        ReferenceCursorList<BookmarksQuery['bookmarks']>,
        BookmarksQueryVariables
      >,
      allArticles: {
        keyArgs: ['limit', 'filter'],
        merge(existing, incoming) {
          const existingRows = existing ?? []
          const existingRowsSet = new Set(
            // eslint-disable-next-line no-underscore-dangle
            existingRows.map((row: any) => row.__ref),
          )
          const incomingRows =
            incoming?.filter(
              (incomingRow: any) =>
                // eslint-disable-next-line no-underscore-dangle
                !existingRowsSet.has(incomingRow.__ref),
            ) ?? []

          return [...existingRows, ...incomingRows]
        },
      } as ConcreteFieldPolicy<
        DatoCmsAllArticlesQuery['allArticles'],
        DatoCmsAllArticlesQueryVariables
      >,
      allInsights: {
        keyArgs: ['limit', 'filter'],
        merge(existing, incoming) {
          const existingRows = existing ?? []
          const existingRowsSet = new Set(
            // eslint-disable-next-line no-underscore-dangle
            existingRows.map((row: any) => row.__ref),
          )
          const incomingRows =
            incoming?.filter(
              (incomingRow: any) =>
                // eslint-disable-next-line no-underscore-dangle
                !existingRowsSet.has(incomingRow.__ref),
            ) ?? []

          return [...existingRows, ...incomingRows]
        },
      } as ConcreteFieldPolicy<
        DatoCmsAllInsightsQuery['allInsights'],
        DatoCmsAllInsightsQueryVariables
      >,
    },
  },
} as TypePolicies
