import MuiTheme from './MuiTheme'

const DefaultTheme = {
  name: 'Default theme',
  dark: false,

  MuiTheme,

  webfonts: ['Work+Sans:300,400,500,600'],
}

export default DefaultTheme
