export enum VerifyEmailPageParam {
  Email = 'email',
}

export enum ResetPasswordPageParam {
  Token = 'token',
}

export enum InsightType {
  Article = 'article',
  Podcast = 'podcast',
  Masterclass = 'masterclass',
}

export enum ChatKind {
  Text = 'text',
  Audio = 'audio',
  Video = 'video',
}
