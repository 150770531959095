import React, { useCallback, useEffect, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router'

import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import { Stack, Typography } from '@mui/material'
import ms from 'ms'
import { enqueueSnackbar } from 'notistack'
import { useVisibilityChange } from 'use-visibility-change'
import Utils from 'Utils'

import { AuthHeader } from 'Components/Blocks'

import { STRIPE } from 'Config'

import {
  DatoCmsAllSubscriptionPlansDocument,
  SubscriptionPlanModelOrderBy,
  SubscriptionPlanRecordFragment,
} from 'GraphQL/DatoCMS/TypedDocuments'
import {
  CreateCheckoutSessionDocument,
  MeDocument,
  SubscriptionPlanKind,
} from 'GraphQL/Main/TypedDocuments'

import { useAppContext } from 'Services/AppContext'
import { SafeAreaView } from 'Services/SafeArea'

import PlanItem from './PlanItem'

function SubscriptionPage() {
  const { search } = useLocation()
  const client = useApolloClient()
  const history = useHistory()

  const { viewer } = useAppContext()

  const { data: subscriptionPlansData } = useQuery(
    DatoCmsAllSubscriptionPlansDocument,
    {
      variables: {
        orderBy: [SubscriptionPlanModelOrderBy.CreatedAtAsc],
      },
    },
  )

  const [createCheckoutSession] = useMutation(CreateCheckoutSessionDocument)

  const currentPlan = useMemo(
    () =>
      subscriptionPlansData?.allSubscriptionPlans?.find(
        item => item.kind === viewer?.userSubscription?.planKind,
      ),
    [viewer, subscriptionPlansData],
  )

  const canceled = useMemo(() => {
    const searchParams = new URLSearchParams(search)
    return !!searchParams.get('canceled')
  }, [search])

  const handleGoBack = useCallback(
    () => (canceled ? history.go(-3) : history.goBack()),
    [canceled, history],
  )

  // TODO: Listen for subscription events when backend will implement them
  useVisibilityChange({
    onShow: () => {
      client.refetchQueries({ include: [MeDocument] })
    },
  })

  useEffect(() => {
    if (canceled) {
      enqueueSnackbar('You have canceled your subscription process', {
        variant: 'warning',
      })
    }
  }, [canceled])

  const handleSubscribe = useCallback(
    async (plan: SubscriptionPlanRecordFragment) => {
      const isNextFree = Utils.Billing.isFree(plan)

      if (currentPlan) {
        if (isNextFree) {
          enqueueSnackbar(
            "In order to cancel the subscription, you will be redirected to Stripe's dashboard",
            { variant: 'info' },
          )
        } else {
          enqueueSnackbar(
            "In order to change the subscription, you will be redirected to Stripe's dashboard",
            { variant: 'info' },
          )
        }

        setTimeout(() => {
          window.location.href = STRIPE.ACCOUNT_MANAGEMENT_URL
        }, ms('3s'))

        return
      }

      try {
        const { data } = await createCheckoutSession({
          variables: {
            planKind: plan.kind as SubscriptionPlanKind,
          },
        })
        if (data?.createCheckoutSession) {
          window.location.href = data.createCheckoutSession
        }
      } catch (error) {
        const [graphQLError] = Utils.Errors.getGraphQLErrors(error)
        enqueueSnackbar(graphQLError, { variant: 'error' })
      }
    },
    [currentPlan, createCheckoutSession],
  )

  return (
    <Stack flexGrow={1}>
      <AuthHeader onGoBack={handleGoBack} />

      <Stack pb={1.5} px={2}>
        <Typography align="center" variant="h5">
          Subscription plans
        </Typography>

        <Typography align="center" color="text.secondary" mt={1.5}>
          Select your plan to benefit from real-time insights
        </Typography>

        <Stack mt={3} spacing={1}>
          {subscriptionPlansData?.allSubscriptionPlans.map(item => (
            <PlanItem
              currentPlan={currentPlan as any}
              item={item}
              key={item.id}
              onSubscribe={() => handleSubscribe(item)}
            />
          ))}
        </Stack>
      </Stack>

      <SafeAreaView bottom />
    </Stack>
  )
}

export default SubscriptionPage
