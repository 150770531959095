import React from 'react'

import {
  AutoStoriesOutlined,
  LanguageOutlined,
  VideocamOutlined,
} from '@mui/icons-material'
import { Button, Modal, Stack, Typography } from '@mui/material'

import * as paths from 'Constants/paths'

type Props = {
  open?: boolean
  onRequestClose?: () => void
}

function SubscriptionAIDialog({ open, onRequestClose }: Props) {
  return (
    <Modal
      open={!!open}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        p: 2,
      }}
      onClose={onRequestClose}
    >
      <Stack
        sx={{
          bgcolor: 'background.paper',
          borderRadius: 3,
          p: 2,
        }}
      >
        <Typography color="success.light" variant="overline">
          AI CHAT
        </Typography>

        <Typography sx={{ mt: 2 }} variant="h5">
          Subscribe to get access
        </Typography>

        <Typography sx={{ mt: 1 }} variant="subtitle2">
          Talk to Dr Mark van Rijmenam&apos;s digital twin!
        </Typography>

        <Stack mt={3} spacing={1.5}>
          <Stack alignItems="center" direction="row" spacing={2}>
            <AutoStoriesOutlined sx={{ color: 'primary.main', fontSize: 20 }} />

            <Typography color="text.secondary" variant="caption">
              Answer all your technology questions. (Just ask me anything you
              like, as long as it is related to technologies!)
            </Typography>
          </Stack>

          <Stack alignItems="center" direction="row" spacing={2}>
            <LanguageOutlined sx={{ color: 'primary.main', fontSize: 20 }} />

            <Typography color="text.secondary" variant="caption">
              I speak 28 languages, including English, Arabic, Chinese, Hindi,
              Dutch, French, Spanish, German, Korean and more!
            </Typography>
          </Stack>

          <Stack alignItems="center" direction="row" spacing={2}>
            <VideocamOutlined sx={{ color: 'primary.main', fontSize: 20 }} />

            <Typography color="text.secondary" variant="caption">
              If you do not want to type, you can also speak to me or video call
              me!
            </Typography>
          </Stack>
        </Stack>

        <Stack mt={4} spacing={1.5}>
          <Button href={paths.SUBSCRIPTION} variant="contained">
            Subscribe
          </Button>

          <Button onClick={onRequestClose}>I need some time to think</Button>
        </Stack>
      </Stack>
    </Modal>
  )
}

export default SubscriptionAIDialog
