import React, { useMemo } from 'react'

import { Box, BoxProps } from '@mui/material'

export function useSafeAreaInsets({
  top,
  bottom,
  left,
  right,
}: SafeAreaEdgeProps = {}) {
  return useMemo(
    () => ({
      top: top ? 'env(safe-area-inset-top)' : undefined,
      right: right ? 'env(safe-area-inset-right)' : undefined,
      bottom: bottom ? 'env(safe-area-inset-bottom)' : undefined,
      left: left ? 'env(safe-area-inset-left)' : undefined,
    }),
    [top, bottom, left, right],
  )
}

type SafeAreaEdgeProps = {
  bottom?: boolean
  left?: boolean
  right?: boolean
  top?: boolean
}

type SafeAreaViewProps = Omit<BoxProps, keyof SafeAreaEdgeProps> &
  SafeAreaEdgeProps

export function SafeAreaView({
  top,
  bottom,
  left,
  right,
  ...rest
}: SafeAreaViewProps) {
  const insets = useSafeAreaInsets({ top, bottom, left, right })
  const insetsStyle = useMemo(
    () => ({
      pt: insets.top,
      pr: insets.right,
      pb: insets.bottom,
      pl: insets.left,
    }),
    [insets],
  )

  return (
    <Box
      {...rest}
      pb={insetsStyle.pb}
      pl={insetsStyle.pl}
      pr={insetsStyle.pr}
      pt={insetsStyle.pt}
    />
  )
}
