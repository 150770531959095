import { Components, Theme } from '@mui/material'

import { LinkBehavior } from 'Components/Blocks'

const MuiBottomNavigationAction: Components<Theme>['MuiBottomNavigationAction'] =
  {
    defaultProps: {
      component: LinkBehavior,
    },
  }

export default MuiBottomNavigationAction
