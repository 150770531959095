import React from 'react'
import { Redirect } from 'react-router'
import { RouteConfig } from 'react-router-config'

import * as paths from 'Constants/paths'

import { AdminLayout } from 'Containers/Layout'
import {
  AdminArticlesPage,
  AdminInsightsPage,
  AdminUsersPage,
} from 'Containers/Pages/Admin'

function adminRoutes(): RouteConfig[] {
  return [
    {
      path: paths.ADMIN,
      component: AdminLayout,
      routes: [
        {
          path: paths.ADMIN_USERS,
          exact: true,
          component: AdminUsersPage,
        },
        {
          path: paths.ADMIN_ARTICLES,
          exact: true,
          component: AdminArticlesPage,
        },
        {
          path: paths.ADMIN_INSIGHTS,
          exact: true,
          component: AdminInsightsPage,
        },
        {
          path: paths.ADMIN,
          exact: true,
          component: () => <Redirect to={paths.ADMIN_USERS} />,
        },
      ],
    },
  ]
}

export default adminRoutes
