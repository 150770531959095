import { BottomNavigation, styled } from '@mui/material'

export const StyledBottomNavigation = styled(BottomNavigation)(({ theme }) => ({
  overflow: 'hidden',
  backgroundColor: 'transparent',

  '& .MuiBottomNavigationAction-label': {
    ...theme.typography.caption,
    '&.Mui-selected': theme.typography.caption,
  },
}))
