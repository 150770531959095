import React from 'react'
import { renderRoutes } from 'react-router-config'

import { GlobalStyles } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'

import { LocationTracker } from 'Components/Blocks'

import { routes } from 'Config/Routes'

import { useAppContext } from 'Services/AppContext'
import { useApp } from 'Services/Store/app'

import Fonts from './Fonts'

function AppContainer() {
  const { accessGranted, welcomed, notificationsRequested } = useApp()
  const { viewer, initialLoading } = useAppContext()

  return (
    <>
      <GlobalStyles
        styles={{
          // NOTE: We use 100vh only in PWA fullscreen mode to fix all visual artifacts
          //       related to lack of browser UI elements on top and bottom of the page
          '@media all and (display-mode: standalone)': {
            body: {
              height: '100vh',
            },
          },
        }}
      />
      <LocationTracker />
      <CssBaseline />
      <Fonts />

      {renderRoutes(
        routes({
          viewer,
          welcomed,
          notificationsRequested,
          accessGranted,
          initialLoading,
        }),
      )}
    </>
  )
}

export default AppContainer
