import themeGet from '@styled-system/theme-get'

import { ListItem, ListItemProps } from '@mui/material'
import { styled } from '@mui/system'

interface StyledListItemProps extends ListItemProps {
  activeClassName?: string
  to?: string
  mt?: string
}

export const StyledListItem = styled(ListItem)<StyledListItemProps>`
  cursor: pointer;

  &:visited,
  &:focus,
  &:active {
    color: ${themeGet('palette.text.primary')};
  }

  && {
    &.active {
      color: ${themeGet('palette.primary.main')};
      svg {
        color: ${themeGet('palette.primary.main')};
      }
    }
  }
`
