import { Stack, styled } from '@mui/material'

export const Feature = styled(Stack)(({ theme }) =>
  theme.unstable_sx({
    border: 1,
    borderColor: 'divider',
    borderRadius: 3,
    bgcolor: 'background.paper',
  }),
)
