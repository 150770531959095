import React, { useCallback } from 'react'
import { useHistory } from 'react-router'

import { Box, Button, Stack, Typography } from '@mui/material'

import { AuthHeader, VimeoPlayer } from 'Components/Blocks'

import * as paths from 'Constants/paths'

import { SafeAreaView } from 'Services/SafeArea'
import { useApp } from 'Services/Store/app'

function WelcomePage() {
  const history = useHistory()
  const { setWelcomed } = useApp()

  const handleSkipClick = useCallback(() => {
    setWelcomed(true)
    history.replace(paths.ROOT)
  }, [history, setWelcomed])

  return (
    <Stack flexGrow={1}>
      <AuthHeader closeable onClose={handleSkipClick} />

      <Stack flexGrow={1} px={4} py={2}>
        <Stack flexGrow={1}>
          <Typography align="center" variant="h5">
            Welcome
          </Typography>

          <Typography align="justify" color="text.secondary" mt={1.5}>
            Thank you for signing up for The Digital Speaker app. I am super
            excited that you are here and please enjoy my below welcome message.
            <br />
            <br />
            Don&apos;t forget that you can save this application on your home
            screen, by clicking the share button in Safari (iOS) or Chrome
            (Android). Head over to the FAQs for more information.
            <br />
            <br />
            Enjoy this unique experience!
          </Typography>

          <Box mt={6}>
            <VimeoPlayer id="894366617" />
          </Box>
        </Stack>

        <Button sx={{ mt: 2 }} variant="text" onClick={handleSkipClick}>
          Skip
        </Button>
      </Stack>

      <SafeAreaView bottom />
    </Stack>
  )
}

export default WelcomePage
