import React, { useMemo, useState } from 'react'

import { ThemeProvider as SCThemeProvider } from 'styled-components'

import { useQuery } from '@apollo/client'
import { ThemeProvider as MUIThemeProvider } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { SnackbarProvider } from 'notistack'

import { MeDocument } from 'GraphQL/Main/TypedDocuments'

import AppContainer from 'Containers/App'

import { AppContext } from 'Services/AppContext'
import { useSession } from 'Services/Store/session'
import { useUI } from 'Services/Store/ui'

function App() {
  const { theme } = useUI()
  const { authenticated } = useSession()

  const [initialLoading, setInitialLoading] = useState(authenticated)

  const { data } = useQuery(MeDocument, {
    skip: !authenticated,
    notifyOnNetworkStatusChange: true,

    onCompleted: () => {
      setInitialLoading(false)
    },
  })

  const me = data?.me || undefined

  const appContextValue = useMemo(
    () => ({ viewer: me, initialLoading }),
    [me, initialLoading],
  )

  return (
    <AppContext.Provider value={appContextValue}>
      <SnackbarProvider autoHideDuration={5000} maxSnack={3}>
        <SCThemeProvider theme={theme}>
          <MUIThemeProvider theme={theme.MuiTheme}>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <AppContainer />
            </LocalizationProvider>
          </MUIThemeProvider>
        </SCThemeProvider>
      </SnackbarProvider>
    </AppContext.Provider>
  )
}

export default App
