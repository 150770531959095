import { Components, Theme } from '@mui/material'

import { LinkBehavior } from 'Components/Blocks'

const MuiButtonBase: Components<Theme>['MuiButtonBase'] = {
  defaultProps: {
    LinkComponent: LinkBehavior,
  },
}

export default MuiButtonBase
