import { Stack } from '@mui/material'
import { styled } from '@mui/system'

export const UserInfoContainer = styled(Stack)(({ theme }) =>
  theme.unstable_sx({
    border: 1,
    borderColor: 'divider',
    borderRadius: 3,
    bgcolor: 'background.paper',
  }),
)
