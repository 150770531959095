import React from 'react'

import { Button, Divider, Stack, Typography } from '@mui/material'

import { LogoImage } from 'Components/Blocks'

import * as paths from 'Constants/paths'

function AuthPage() {
  return (
    <Stack flexGrow={1}>
      <Stack
        alignItems="center"
        flexGrow={1}
        justifyContent="center"
        px={6}
        spacing={2}
      >
        <LogoImage height={150} width={200} />

        <Divider sx={{ width: '100%' }} />

        <Typography align="center" color="text.secondary" variant="subtitle2">
          Your on-demand strategic futurist and keynote speaker
        </Typography>
      </Stack>

      <Stack p={4} spacing={1}>
        <Button href={paths.SIGN_UP} size="large" variant="contained">
          Register
        </Button>

        <Button href={paths.SIGN_IN} size="large">
          I have an account
        </Button>
      </Stack>
    </Stack>
  )
}

export default AuthPage
