import React, { useCallback, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { useMutation } from '@apollo/client'
import { zodResolver } from '@hookform/resolvers/zod'
import { LoadingButton } from '@mui/lab'
import { Autocomplete, Box, Stack, TextField, Typography } from '@mui/material'
import { enqueueSnackbar } from 'notistack'
import Utils from 'Utils'
import { z } from 'zod'

import { CountryFlag, TitleHeader } from 'Components/Blocks'

import { COUNTRIES, CountryType } from 'Constants/countries'

import {
  UpdateProfileDocument,
  UserProfileFragment,
} from 'GraphQL/Main/TypedDocuments'

import { useAppContext } from 'Services/AppContext'

enum Fields {
  FirstName = 'firstName',
  LastName = 'lastName',
  Email = 'email',
  Country = 'country',
  Industry = 'industry',
}

type Values = z.infer<typeof schema>

const schema = z.object({
  [Fields.FirstName]: z.string(),
  [Fields.LastName]: z.string(),
  [Fields.Email]: z.string().email('Must be a valid email'),
  [Fields.Country]: z
    .object({
      code: z.string(),
      label: z.string(),
      phone: z.string(),
      suggested: z.boolean().optional(),
    })
    .nullable(),
  [Fields.Industry]: z.string(),
})

function countryToLocation(country?: CountryType | null) {
  return country?.code ?? null
}

function locationToCountry(location?: UserProfileFragment['location']) {
  return COUNTRIES.find(item => item.code === location) ?? null
}

function UpdateProfilePage() {
  const { viewer } = useAppContext()

  const [updateProfile, { loading }] = useMutation(UpdateProfileDocument)

  const defaultValues = useMemo(
    () => ({
      [Fields.FirstName]: viewer?.profile?.firstName ?? '',
      [Fields.LastName]: viewer?.profile?.lastName ?? '',
      [Fields.Email]: viewer?.primaryEmailCredential?.email ?? '',
      [Fields.Industry]: viewer?.profile?.industry ?? '',
      [Fields.Country]: locationToCountry(viewer?.profile?.location),
    }),
    [viewer],
  )

  const { control, handleSubmit } = useForm<Values>({
    resolver: zodResolver(schema),
    defaultValues,
  })

  const onSubmit = useCallback(
    async (values: Values) => {
      try {
        await updateProfile({
          variables: {
            firstName: values[Fields.FirstName],
            lastName: values[Fields.LastName],
            location: countryToLocation(values[Fields.Country]),
            industry: values[Fields.Industry],
          },
        })

        enqueueSnackbar('You have successfully updated your profile', {
          variant: 'success',
        })
      } catch (error) {
        const [graphQLError] = Utils.Errors.getGraphQLErrors(error)
        enqueueSnackbar(graphQLError, { variant: 'error' })
      }
    },
    [updateProfile],
  )

  return (
    <Stack flexGrow={1}>
      <TitleHeader title="Edit profile" />

      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack p={2} spacing={5}>
          <Stack spacing={2.5}>
            <Controller
              control={control}
              name={Fields.Email}
              render={({ field: { value, onChange }, fieldState }) => (
                <TextField
                  autoCapitalize="none"
                  disabled
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  inputProps={{
                    type: 'email',
                  }}
                  label="Email"
                  value={value}
                  variant="standard"
                  onChange={onChange}
                />
              )}
            />

            <Controller
              control={control}
              name={Fields.FirstName}
              render={({ field: { value, onChange }, fieldState }) => (
                <TextField
                  autoCapitalize="none"
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  label="First name"
                  value={value}
                  variant="standard"
                  onChange={onChange}
                />
              )}
            />

            <Controller
              control={control}
              name={Fields.LastName}
              render={({ field: { value, onChange }, fieldState }) => (
                <TextField
                  autoCapitalize="none"
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  label="Last name"
                  value={value}
                  variant="standard"
                  onChange={onChange}
                />
              )}
            />

            <Controller
              control={control}
              name={Fields.Country}
              render={({ field: { value, onChange } }) => (
                <Autocomplete
                  autoHighlight
                  getOptionLabel={option => option.label}
                  isOptionEqualToValue={(option, value) =>
                    option.code === value?.code
                  }
                  options={COUNTRIES}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Location"
                      variant="standard"
                    />
                  )}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      <CountryFlag country={option} size={20} />
                      {option.label} ({option.code}) +{option.phone}
                    </Box>
                  )}
                  value={value}
                  onChange={(event, nextValue) => onChange?.(nextValue)}
                />
              )}
            />

            <Controller
              control={control}
              name={Fields.Industry}
              render={({ field: { value, onChange }, fieldState }) => (
                <TextField
                  autoCapitalize="none"
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  label="Industry"
                  value={value}
                  variant="standard"
                  onChange={onChange}
                />
              )}
            />
          </Stack>

          <Stack spacing={3}>
            <LoadingButton
              loading={loading}
              size="large"
              type="submit"
              variant="contained"
            >
              Save
            </LoadingButton>

            <Typography align="center" color="text.secondary" variant="caption">
              Only you can see your personal data. No one else will be able to
              see it
            </Typography>
          </Stack>
        </Stack>
      </form>
    </Stack>
  )
}

export default UpdateProfilePage
