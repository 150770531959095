import DarkTheme from './Dark'
import DefaultTheme from './Default'

enum ThemeID {
  Default = 'default',
  Dark = 'dark',
}

const THEMES = {
  [ThemeID.Default]: DefaultTheme,
  [ThemeID.Dark]: DarkTheme,
}

const DEFAULT_THEME_ID = ThemeID.Default

export { DEFAULT_THEME_ID, ThemeID, THEMES }
