import React from 'react'

import { CircularProgress, Stack } from '@mui/material'

import { LogoImage } from 'Components/Blocks'

function InitialLoadingPage() {
  return (
    <Stack
      alignItems="center"
      bgcolor="background.default"
      flexGrow={1}
      justifyContent="center"
      spacing={8}
    >
      <LogoImage />

      <CircularProgress size={50} />
    </Stack>
  )
}

export default InitialLoadingPage
