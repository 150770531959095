import Envs from 'Services/Envs'

export const APP_ENV = Envs.get('ENV')

export const IS_PRODUCTION = APP_ENV === 'production'
export const IS_STAGING = APP_ENV === 'staging'
export const IS_DEVELOPMENT = APP_ENV === 'development'
export const S3_URL = Envs.get('S3_URL')
export const LANDING_URL = Envs.get('LANDING_URL')

export const APP = {
  NAME: 'the-digital-speaker-frontend',
  PASSWORD_REQUIRED: Envs.get('APP_PASSWORD_REQUIRED') === 'true',
  PASSWORD: Envs.get('APP_PASSWORD'),
}

export const PROXY_URL = Envs.get('PROXY_URL')

const MAIN_API_SSL_ENABLED = Envs.get('MAIN_API_SSL_ENABLED') === 'true'
const MAIN_API_PROTOCOL = MAIN_API_SSL_ENABLED ? 'https' : 'http'
const MAIN_WEBSOCKETS_PROTOCOL = MAIN_API_SSL_ENABLED ? 'wss' : 'ws'

const DATOCMS_API_SSL_ENABLED = Envs.get('DATOCMS_API_SSL_ENABLED') === 'true'
const DATOCMS_API_PROTOCOL = DATOCMS_API_SSL_ENABLED ? 'https' : 'http'
const DATOCMS_WEBSOCKETS_PROTOCOL = DATOCMS_API_SSL_ENABLED ? 'wss' : 'ws'

export const MAIN = {
  API_URL: `${MAIN_API_PROTOCOL}://${Envs.get('MAIN_API_URL')}`,
  WEBSOCKETS_URL: `${MAIN_WEBSOCKETS_PROTOCOL}://${Envs.get('MAIN_API_URL')}`,
}

export const DATOCMS = {
  API_URL: `${DATOCMS_API_PROTOCOL}://${Envs.get('DATOCMS_API_URL')}`,
  API_TOKEN: Envs.get('DATOCMS_API_TOKEN'),
  WEBSOCKETS_URL: `${DATOCMS_WEBSOCKETS_PROTOCOL}://${Envs.get(
    'DATOCMS_API_URL',
  )}`,
}

export const DELPHI = {
  EMBED_URL: Envs.get('DELPHI_EMBED_URL'),
}

export const RAPPORT = {
  PROJECT_ID: Envs.get('RAPPORT_PROJECT_ID'),
  PROJECT_TOKEN: Envs.get('RAPPORT_PROJECT_TOKEN'),
  AI_USER_ID: Envs.get('RAPPORT_AI_USER_ID'),
  LOBBY_ZONE_ID: Envs.get('RAPPORT_LOBBY_ZONE_ID'),
}

export const ONE_SIGNAL = {
  APP_ID: Envs.get('ONE_SIGNAL_APP_ID'),
  SAFARI_WEB_ID: Envs.get('ONE_SIGN_SAFARI_WEB_ID'),
}

export const STRIPE = {
  ACCOUNT_MANAGEMENT_URL: Envs.get('STRIPE_ACCOUNT_MANAGEMENT_URL'),
}

export const STORE = {
  KEY: `${APP.NAME}/store`,
}
