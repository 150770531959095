import React, { useMemo } from 'react'

import { ExpandMore } from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Stack,
  Typography,
} from '@mui/material'
import { SystemProps } from '@mui/system'

import { Markdown } from 'Components/Blocks'

import * as paths from 'Constants/paths'

import { InsightRecordFragment } from 'GraphQL/DatoCMS/TypedDocuments'

type Props = SystemProps & {
  insight: InsightRecordFragment
}

function LecturesTab({ insight, ...rest }: Props) {
  const accordions = useMemo(
    () =>
      insight.lectures.map((item, i) => (
        <Accordion key={item.id}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Stack spacing={0.5}>
              <Typography color="text.secondary" variant="caption">
                Session {i + 1}
              </Typography>

              <Typography variant="subtitle1">{item.title}</Typography>
            </Stack>
          </AccordionSummary>

          <AccordionDetails>
            <Stack spacing={2}>
              <Markdown truncate truncateLimit={300}>
                {item.description}
              </Markdown>

              <Button href={paths.LECTURE(item.id)} variant="contained">
                Start session
              </Button>
            </Stack>
          </AccordionDetails>
        </Accordion>
      )),
    [insight.lectures],
  )

  return <Stack {...rest}>{accordions}</Stack>
}

export default LecturesTab
