import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'

import useScript from '@custom-react-hooks/use-script'
import { CallEnd } from '@mui/icons-material'
import { CircularProgress, Fab, Stack } from '@mui/material'
import { useVisibilityChange } from 'use-visibility-change'

import { TitleHeader } from 'Components/Blocks'

import { RAPPORT } from 'Config'

import * as paths from 'Constants/paths'

import { MeteredPriceKind } from 'GraphQL/Main/TypedDocuments'

import { useBillingTracker } from 'Services/Billing'

function AIVideoPage() {
  const [loading, setLoading] = useState(true)

  const sceneRef = useRef<any>()

  const history = useHistory()

  useBillingTracker(MeteredPriceKind.Video, {
    paused: loading,
  })

  const endSession = useCallback(() => {
    history.replace(paths.AI)
  }, [history])

  useVisibilityChange({
    shouldReturnResult: false,
    onHide: endSession,
  })

  const scriptStatus = useScript(
    'https://cdn.rapport.cloud/rapport-web-viewer/rapport.js',
  )

  useEffect(() => {
    const scene = sceneRef.current

    const startSession = async () => {
      if (scriptStatus !== 'ready' || !scene) return

      try {
        await scene.sessionRequest({
          projectId: RAPPORT.PROJECT_ID,
          projectToken: RAPPORT.PROJECT_TOKEN,
          aiUserId: RAPPORT.AI_USER_ID,
          lobbyZoneId: RAPPORT.LOBBY_ZONE_ID,
          micRequired: true,
          sessionConnected: () => {
            setLoading(false)
          },
        })
      } catch (error) {
        //
      }
    }

    startSession()

    return () => {
      scene?.sessionDisconnect().then()
    }
  }, [scriptStatus])

  return (
    <Stack flexGrow={1}>
      <TitleHeader title="Video" />

      {loading && (
        <Stack alignItems="center" flexGrow={1} justifyContent="center">
          <CircularProgress />
        </Stack>
      )}

      <rapport-scene
        ref={sceneRef}
        style={{
          width: '100%',
          height: '100%',
          display: loading ? 'none' : 'block',
        }}
      />

      <Fab
        color="error"
        sx={theme => ({
          position: 'fixed',
          left: '50%',
          transform: 'translateX(-50%)',
          bottom: theme.spacing(2),
        })}
        onClick={endSession}
      >
        <CallEnd color="inherit" />
      </Fab>
    </Stack>
  )
}

export default AIVideoPage
