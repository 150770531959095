import React from 'react'
import { Redirect } from 'react-router'
import { RouteConfig } from 'react-router-config'

import * as paths from 'Constants/paths'

import { UserFragment } from 'GraphQL/Main/TypedDocuments'

import { MainLayout } from 'Containers/Layout'
import {
  RequestResetPasswordPage,
  ResetPasswordPage,
} from 'Containers/Pages/Auth'
import {
  AIAudioPage,
  AIPage,
  AITextPage,
  AIVideoPage,
  ArticlePage,
  BookmarksPage,
  ContactUsPage,
  ContactUsSuccessPage,
  FeedPage,
  InsightBrowserPage,
  InsightPage,
  InsightsPage,
  LecturePage,
  LectureQuizPage,
  MenuPage,
  SubscriptionPage,
  SubscriptionSuccessPage,
  SubscriptionUsagePage,
  UpdateProfilePage,
} from 'Containers/Pages/Main'
import NotFoundPage from 'Containers/Pages/NotFound'

function mainRoutes({ viewer }: { viewer?: UserFragment }): RouteConfig[] {
  let allRoutes: RouteConfig[] = []

  if (viewer?.userSubscription) {
    allRoutes = allRoutes.concat([
      {
        path: paths.AI_TEXT,
        exact: true,
        component: AITextPage,
      },
      {
        path: paths.AI_AUDIO,
        exact: true,
        component: AIAudioPage,
      },
      {
        path: paths.AI_VIDEO,
        exact: true,
        component: AIVideoPage,
      },
      {
        path: paths.SUBSCRIPTION_USAGE,
        exact: true,
        component: SubscriptionUsagePage,
      },
    ])
  }

  return allRoutes.concat([
    {
      path: paths.REQUEST_RESET_PASSWORD,
      exact: true,
      component: RequestResetPasswordPage,
    },
    {
      path: paths.RESET_PASSWORD,
      exact: true,
      component: ResetPasswordPage,
    },
    {
      path: paths.PROFILE_UPDATE,
      exact: true,
      component: UpdateProfilePage,
    },
    {
      path: paths.ARTICLE(),
      exact: true,
      component: ArticlePage,
    },
    {
      path: paths.INSIGHT(),
      exact: true,
      component: InsightPage,
    },
    {
      path: paths.INSIGHT_BROWSER(),
      exact: true,
      component: InsightBrowserPage,
    },
    {
      path: paths.LECTURE(),
      exact: true,
      component: LecturePage,
    },
    {
      path: paths.LECTURE_QUIZ(),
      exact: true,
      component: LectureQuizPage,
    },
    {
      path: paths.SUBSCRIPTION,
      exact: true,
      component: SubscriptionPage,
    },
    {
      path: paths.SUBSCRIPTION_SUCCESS,
      exact: true,
      component: SubscriptionSuccessPage,
    },
    {
      path: paths.SUBSCRIPTION_CANCELED,
      exact: true,
      component: () => (
        <Redirect
          to={{ pathname: paths.SUBSCRIPTION, search: '?canceled=true' }}
        />
      ),
    },
    {
      path: paths.CONTACT_US,
      exact: true,
      component: ContactUsPage,
    },
    {
      path: paths.CONTACT_US_SUCCESS,
      exact: true,
      component: ContactUsSuccessPage,
    },

    {
      path: paths.ROOT,
      component: MainLayout,
      routes: [
        {
          path: paths.ROOT,
          exact: true,
          component: () => <Redirect to={paths.FEED} />,
        },
        {
          path: paths.FEED,
          exact: true,
          component: FeedPage,
        },
        {
          path: paths.INSIGHTS,
          exact: true,
          component: InsightsPage,
        },
        {
          path: paths.AI,
          exact: true,
          component: AIPage,
        },
        {
          path: paths.BOOKMARKS,
          exact: true,
          component: BookmarksPage,
        },
        {
          path: paths.MENU,
          exact: true,
          component: MenuPage,
        },
        {
          path: paths.PROFILE,
          exact: true,
          component: () => <Redirect to={paths.PROFILE_UPDATE} />,
        },
        { component: NotFoundPage },
      ],
    },
  ])
}

export default mainRoutes
