import React from 'react'
import { Router } from 'react-router-dom'

import { ApolloProvider } from '@apollo/client'
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'
import { createBrowserHistory } from 'history'
import { StoreContext } from 'storeon/react'

import { createApolloClient } from 'Services/Apollo'
import createQueryClient, { storagePersister } from 'Services/Query'
import { createStore } from 'Services/Store'

import App from './App'

const history = createBrowserHistory()
const store = createStore()
const client = createApolloClient()
const queryClient = createQueryClient()
const persistOptions = { persister: storagePersister }

function Container() {
  return (
    <ApolloProvider client={client}>
      <PersistQueryClientProvider
        client={queryClient}
        persistOptions={persistOptions}
      >
        <StoreContext.Provider value={store}>
          <Router history={history}>
            <App />
          </Router>
        </StoreContext.Provider>
      </PersistQueryClientProvider>
    </ApolloProvider>
  )
}

export default Container
