import React, { useCallback, useState } from 'react'
import { ItemContent, Virtuoso } from 'react-virtuoso'

import { Refresh } from '@mui/icons-material'
import { CircularProgress, Divider, Fab } from '@mui/material'
import { Stack } from '@mui/system'

import { ArticleItem, InsightItem, MainHeader } from 'Components/Blocks'

import { DatoModelKind } from 'GraphQL/Main/TypedDocuments'

import { BookmarkItem, useBookmarks } from './hooks'

function BookmarksPage() {
  const [refreshing, setRefreshing] = useState(false)

  const { bookmarks, loadMore, refetch } = useBookmarks()

  const handleRefresh = useCallback(async () => {
    setRefreshing(true)
    await refetch()
    setRefreshing(false)
  }, [refetch])

  const renderItemContent = useCallback<ItemContent<BookmarkItem, any>>(
    (index, item) => {
      const content = (() => {
        switch (item.kind) {
          case DatoModelKind.Article:
            return <ArticleItem item={item.data} p={2} />
          case DatoModelKind.Insight:
            return <InsightItem item={item.data} p={2} />
          default:
            return null
        }
      })()

      return (
        <Stack>
          {index > 0 && <Divider sx={{ mx: 2 }} />}
          {content}
        </Stack>
      )
    },
    [],
  )

  return (
    <Stack flexGrow={1} position="relative">
      <MainHeader
        description="Your personal library of content"
        title="Bookmarks"
      />

      <Virtuoso
        data={bookmarks}
        endReached={loadMore}
        itemContent={renderItemContent}
      />

      <Fab
        sx={theme => ({
          position: 'absolute',
          bottom: theme.spacing(2),
          right: theme.spacing(2),
        })}
        onClick={handleRefresh}
      >
        {refreshing ? (
          <CircularProgress color="inherit" size={24} />
        ) : (
          <Refresh color="inherit" />
        )}
      </Fab>
    </Stack>
  )
}

export default BookmarksPage
