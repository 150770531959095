import React from 'react'
import { useHistory } from 'react-router'

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { Divider, IconButton, Stack, Typography } from '@mui/material'

import { SafeAreaView } from 'Services/SafeArea'

type Props = {
  title?: string
}

function TitleHeader({ title }: Props) {
  const { goBack } = useHistory()

  return (
    <SafeAreaView bgcolor="background.paper" top>
      <Stack alignItems="center" direction="row" px={1} spacing={1.5}>
        <IconButton size="large" onClick={goBack}>
          <ChevronLeftIcon sx={{ fontSize: '24px' }} />
        </IconButton>

        <Typography noWrap variant="subtitle1">
          {title}
        </Typography>
      </Stack>

      <Divider />
    </SafeAreaView>
  )
}

export default TitleHeader
