import React from 'react'
import { useParams } from 'react-router'

import { useQuery } from '@apollo/client'
import { Button, Stack } from '@mui/material'

import { Markdown, TitleHeader, VimeoPlayer } from 'Components/Blocks'

import * as paths from 'Constants/paths'

import {
  DatoCmsLectureDocument,
  LectureRecordFragment,
} from 'GraphQL/DatoCMS/TypedDocuments'

import { SafeAreaView } from 'Services/SafeArea'

type RouteParams = {
  lectureId?: LectureRecordFragment['id']
}

function LecturePage() {
  const { lectureId } = useParams<RouteParams>()

  const { data } = useQuery(DatoCmsLectureDocument, {
    variables: { filter: { id: { eq: lectureId } } },
    skip: !lectureId,
  })

  const lecture = data?.lecture

  return (
    <Stack flexGrow={1} overflow="hidden">
      <TitleHeader title={lecture?.title ?? ''} />

      {!!lecture?.vimeoId && <VimeoPlayer id={lecture.vimeoId} />}

      <Stack flexGrow={1} overflow="auto">
        <Stack p={2}>
          <Markdown>{lecture?.description}</Markdown>
        </Stack>
      </Stack>

      {!!lecture?.embeddableTfLink && (
        <Stack bgcolor="background.paper" p={2}>
          <Button href={paths.LECTURE_QUIZ(lectureId)} variant="contained">
            Start quiz
          </Button>

          <SafeAreaView bottom />
        </Stack>
      )}
    </Stack>
  )
}

export default LecturePage
