import React from 'react'

import { Divider, Stack, Typography } from '@mui/material'

import { SafeAreaView } from 'Services/SafeArea'

type Props = {
  bottom?: JSX.Element
  description?: string
  right?: JSX.Element
  noBackground?: boolean
  title: string
}

function MainHeader({
  title,
  description,
  right,
  bottom,
  noBackground,
}: Props) {
  return (
    <SafeAreaView bgcolor={noBackground ? undefined : 'background.paper'} top>
      <Stack p={2} spacing={2}>
        <Stack direction="row">
          <Stack flexGrow={1} overflow="hidden" spacing={0.5}>
            <Stack alignItems="center" direction="row" spacing={2}>
              <Typography flexGrow={1} noWrap variant="h5">
                {title}
              </Typography>

              {right}
            </Stack>

            {!!description && (
              <Typography color="text.secondary" noWrap variant="caption">
                {description}
              </Typography>
            )}
          </Stack>
        </Stack>

        {bottom}
      </Stack>

      <Divider />
    </SafeAreaView>
  )
}

export default MainHeader
