import React from 'react'
import { StructuredText } from 'react-datocms'

import { useQuery } from '@apollo/client'
import { Box, Stack } from '@mui/material'

import { TitleHeader } from 'Components/Blocks'

import { DatoCmsFaqPageDocument } from 'GraphQL/DatoCMS/TypedDocuments'

function FaqPage() {
  const { data } = useQuery(DatoCmsFaqPageDocument)

  return (
    <Stack flexGrow={1} overflow="hidden">
      <TitleHeader title="FAQ" />

      <Box overflow="auto" p={2}>
        <StructuredText data={data?.faqPage?.content} />
      </Box>
    </Stack>
  )
}

export default FaqPage
