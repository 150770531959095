import { styled } from '@mui/material'

import { ReactComponent as LogoSvg } from 'Assets/Svg/logo.svg'

const LogoImage = styled(LogoSvg)(({ theme }) => ({
  '& .LogoImage-text': {
    fill: theme.palette.text.primary,
  },

  '& .LogoImage-icon': {
    fill: theme.palette.primary.main,
  },
}))

export default LogoImage
