import React from 'react'

import { Box, Stack, Typography } from '@mui/material'
import Image from 'mui-image'

import { ArticleRecordFragment } from 'GraphQL/DatoCMS/TypedDocuments'

type Props = {
  article: ArticleRecordFragment
}

function Publisher({ article }: Props) {
  return (
    <Stack
      alignItems="center"
      bgcolor="background.paper"
      border={1}
      borderColor="divider"
      borderRadius={3}
      direction="row"
      p={1.5}
      spacing={1.5}
    >
      {article.publisherLogo && (
        <Box flexShrink={0}>
          <Image
            alt={article.title ?? ''}
            height="36px"
            src={article.publisherLogo.url}
            width="36px"
          />
        </Box>
      )}

      <Stack justifyContent="center" overflow="hidden" spacing={0.5}>
        {!!article.publisherName && (
          <Typography color="text.secondary" noWrap variant="caption">
            {article.publisherName}
          </Typography>
        )}

        {!!article.publisherTitle && (
          <Typography noWrap variant="caption">
            {article.publisherTitle}
          </Typography>
        )}
      </Stack>
    </Stack>
  )
}

export default Publisher
