import React from 'react'

import { Stack } from '@mui/material'

import { Browser } from 'Components/Blocks'

import { InsightRecordFragment } from 'GraphQL/DatoCMS/TypedDocuments'

type Props = {
  insight: InsightRecordFragment
}

function Article({ insight }: Props) {
  return (
    <Stack flexGrow={1}>
      {!!insight.articleLink && (
        <Browser src={insight.articleLink} title="Article" />
      )}
    </Stack>
  )
}

export default Article
