import React from 'react'
import { Redirect } from 'react-router'
import { RouteConfig } from 'react-router-config'

import * as paths from 'Constants/paths'

import { UserFragment, UserRole } from 'GraphQL/Main/TypedDocuments'

import { RootLayout } from 'Containers/Layout'
import AccessRequestorPage from 'Containers/Pages/AccessRequestor'
import InitialLoadingPage from 'Containers/Pages/InitialLoading'
import { NotificationsPage, WelcomePage } from 'Containers/Pages/Main'
import NotFoundPage from 'Containers/Pages/NotFound'
import RedirectorPage from 'Containers/Pages/Redirector'

import adminRoutes from './admin'
import authRoutes from './auth'
import legalRoutes from './legal'
import mainRoutes from './main'

function routes({
  viewer,
  initialLoading,
  accessGranted,
  welcomed,
  notificationsRequested,
}: {
  viewer?: UserFragment
  initialLoading?: boolean
  accessGranted?: boolean
  welcomed?: boolean
  notificationsRequested?: boolean
}) {
  const allRoutes = (() => {
    let result: RouteConfig[] = []

    if (initialLoading) {
      return result.concat([{ component: InitialLoadingPage }])
    }

    if (!accessGranted) {
      return result.concat([
        {
          path: paths.ACCESS_REQUESTOR,
          exact: true,
          component: AccessRequestorPage,
        },
        { component: () => <Redirect to={paths.ACCESS_REQUESTOR} /> },
      ])
    }

    result = result.concat([
      {
        path: paths.REDIRECTOR,
        exact: true,
        component: RedirectorPage,
      },
    ])
    result = result.concat(legalRoutes())

    if (!viewer) {
      result = result.concat(authRoutes())
      result = result.concat([
        {
          path: paths.ROOT,
          component: () => <Redirect to={paths.AUTH} />,
        },
      ])

      return result
    }

    if (viewer.role === UserRole.SuperAdmin) {
      result = result.concat(adminRoutes())
      result = result.concat([
        {
          path: paths.ROOT,
          component: () => <Redirect to={paths.ADMIN_USERS} />,
        },
      ])
    } else {
      result = result.concat([
        {
          path: paths.NOTIFICATIONS,
          exact: true,
          component: NotificationsPage,
        },
        {
          path: paths.WELCOME,
          exact: true,
          component: WelcomePage,
        },
      ])

      if (!welcomed) {
        result.push({
          component: () => <Redirect to={paths.WELCOME} />,
        })
      } else if (!notificationsRequested) {
        result.push({
          component: () => <Redirect to={paths.NOTIFICATIONS} />,
        })
      } else {
        result = result.concat(mainRoutes({ viewer }))
        result = result.concat([
          {
            path: paths.AUTH,
            component: () => <Redirect to={paths.ROOT} />,
          },
        ])
      }
    }

    return result
  })()

  return [
    {
      component: RootLayout,
      routes: [...allRoutes, { component: NotFoundPage }],
    },
  ]
}

export { routes }
