import React, { useCallback } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { useMutation } from '@apollo/client'
import { zodResolver } from '@hookform/resolvers/zod'
import { MailOutlined } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { InputAdornment, Stack, TextField, Typography } from '@mui/material'
import { enqueueSnackbar } from 'notistack'
import Utils from 'Utils'
import { z } from 'zod'

import { AuthHeader } from 'Components/Blocks'

import { RequestChangeEmailPasswordDocument } from 'GraphQL/Main/TypedDocuments'

import { useAppContext } from 'Services/AppContext'

enum Fields {
  Email = 'email',
}

type Values = z.infer<typeof schema>

const schema = z.object({
  [Fields.Email]: z.string().email('Must be a valid email'),
})

function RequestResetPasswordPage() {
  const { viewer } = useAppContext()

  const [requestChangeEmailPassword, { loading }] = useMutation(
    RequestChangeEmailPasswordDocument,
  )

  const { control, reset, handleSubmit } = useForm<Values>({
    resolver: zodResolver(schema),
    defaultValues: {
      [Fields.Email]: viewer?.primaryEmailCredential?.email ?? '',
    },
  })

  const onSubmit = useCallback(
    async (values: Values) => {
      try {
        const response = await requestChangeEmailPassword({
          variables: {
            email: values[Fields.Email],
          },
        })

        if (response.data?.requestChangeEmailPassword.ok) {
          if (viewer) {
            enqueueSnackbar(
              'We have sent you a link with further instructions',
              { variant: 'success' },
            )
          } else {
            enqueueSnackbar(
              'If the email address exists in our database, we will send you a link with further instructions',
              { variant: 'info' },
            )
          }
        }
      } catch (error) {
        const [graphQLError] = Utils.Errors.getGraphQLErrors(error)
        enqueueSnackbar(graphQLError, { variant: 'error' })
      }

      reset()
    },
    [viewer, reset, requestChangeEmailPassword],
  )

  return (
    <Stack flexGrow={1}>
      <AuthHeader />

      <Stack flexGrow={1} px={5}>
        <Typography align="center" variant="h5">
          Reset password
        </Typography>

        <Typography align="center" color="text.secondary" mt={1.5}>
          No worries! Enter your email address below and we will send you a code
          to reset your password.
        </Typography>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack mt={4} spacing={5}>
            <Stack spacing={2.5}>
              <Controller
                control={control}
                name={Fields.Email}
                render={({ field: { value, onChange }, fieldState }) => (
                  <TextField
                    autoCapitalize="none"
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    inputProps={{
                      type: 'email',
                      startAdornment: (
                        <InputAdornment position="start">
                          <MailOutlined />
                        </InputAdornment>
                      ),
                    }}
                    label="Email"
                    value={value}
                    variant="standard"
                    onChange={onChange}
                  />
                )}
              />
            </Stack>

            <LoadingButton
              loading={loading}
              size="large"
              type="submit"
              variant="contained"
            >
              Send reset link
            </LoadingButton>
          </Stack>
        </form>
      </Stack>
    </Stack>
  )
}

export default RequestResetPasswordPage
