import { RouteConfig } from 'react-router-config'

import * as paths from 'Constants/paths'

import { LegalLayout } from 'Containers/Layout'
import {
  FaqPage,
  PrivacyPolicyPage,
  TermsOfServicePage,
} from 'Containers/Pages/Legal'

function legalRoutes(): RouteConfig[] {
  return [
    {
      path: paths.LEGAL,
      component: LegalLayout,
      routes: [
        {
          path: paths.FAQ,
          exact: true,
          component: FaqPage,
        },
        {
          path: paths.PRIVACY_POLICY,
          exact: true,
          component: PrivacyPolicyPage,
        },
        {
          path: paths.TERMS_OF_SERVICE,
          exact: true,
          component: TermsOfServicePage,
        },
      ],
    },
  ]
}

export default legalRoutes
