import {
  ApolloClient,
  ApolloLink,
  DocumentNode,
  InMemoryCache,
  MutationHookOptions,
  OperationVariables,
  QueryHookOptions,
  TypedDocumentNode,
  useLazyQuery,
  useMutation,
  useQuery,
  useSubscription,
} from '@apollo/client'

import Shared from 'Services/Shared'

import dataIdFromObject from './dataIdFromObject'
import {
  createAuthLink,
  createErrorLink,
  createRequestLink,
  createRetryLink,
  tokenRefreshLink,
} from './Links'
import typePolicies from './TypePolicies'

type UseAdminQueryOptions<
  TData = any,
  TVariables extends OperationVariables = OperationVariables,
> = QueryHookOptions<TData, TVariables>

type UseAdminMutationOptions<
  TData = any,
  TVariables extends OperationVariables = OperationVariables,
> = MutationHookOptions<TData, TVariables>

function createApolloClient() {
  const cache = new InMemoryCache({
    dataIdFromObject,
    typePolicies,
  })

  const client = new ApolloClient({
    // TODO: add token refresh link
    link: ApolloLink.from([
      tokenRefreshLink,
      createErrorLink(),
      createRetryLink(),
      createAuthLink(),
      createRequestLink(),
    ]),
    cache,
    connectToDevTools: true,
  })

  Shared.setApolloClient(client)

  return client
}

function appendAdminContext(options) {
  return {
    ...options,
    context: { ...options?.context, admin: true },
  }
}

function useAdminQuery<
  TData = any,
  TVariables extends OperationVariables = OperationVariables,
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: UseAdminQueryOptions<TData, TVariables>,
) {
  return useQuery<TData, TVariables>(query, appendAdminContext(options))
}
function useAdminLazyQuery<
  TData = any,
  TVariables extends OperationVariables = OperationVariables,
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: UseAdminQueryOptions<TData, TVariables>,
) {
  return useLazyQuery<TData, TVariables>(query, appendAdminContext(options))
}

function useAdminMutation<
  TData = any,
  TVariables extends OperationVariables = OperationVariables,
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: UseAdminMutationOptions<TData, TVariables>,
) {
  return useMutation<TData, TVariables>(query, appendAdminContext(options))
}

function useAdminSubscription<
  TData = any,
  TVariables extends OperationVariables = OperationVariables,
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: UseAdminQueryOptions<TData, TVariables>,
) {
  return useSubscription<TData, TVariables>(query, appendAdminContext(options))
}

export {
  createApolloClient,
  useAdminLazyQuery,
  useAdminMutation,
  useAdminQuery,
  useAdminSubscription,
}
