import React from 'react'

import { Button, Modal, Stack, Typography } from '@mui/material'

import * as paths from 'Constants/paths'

import LogoImage from '../LogoImage'

type Props = {
  open?: boolean
  onRequestClose?: () => void
}

function SubscriptionDialog({ open, onRequestClose }: Props) {
  return (
    <Modal
      open={!!open}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        p: 2,
      }}
      onClose={onRequestClose}
    >
      <Stack
        sx={{
          bgcolor: 'background.paper',
          borderRadius: 3,
          p: 4,
        }}
      >
        <LogoImage height={72} sx={{ alignSelf: 'center' }} width={88} />

        <Typography align="center" sx={{ mt: 4 }} variant="h6">
          Subscribe to get full access
        </Typography>

        <Typography
          align="center"
          color="text.secondary"
          sx={{ mt: 1.5 }}
          variant="body2"
        >
          Learn how to Unleash the Power of Digital Innovation. Don&apos;t be
          left behind but join this unique masterclass.
        </Typography>

        <Stack mt={4} spacing={1.5}>
          <Button href={paths.SUBSCRIPTION} variant="contained">
            Subscribe
          </Button>
          <Button onClick={onRequestClose}>I need some time to think</Button>
        </Stack>
      </Stack>
    </Modal>
  )
}

export default SubscriptionDialog
