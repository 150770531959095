import React, { useCallback } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory } from 'react-router'

import { useMutation } from '@apollo/client'
import { zodResolver } from '@hookform/resolvers/zod'
import { LoadingButton } from '@mui/lab'
import { Stack, TextField, Typography } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { DateTime } from 'luxon'
import { enqueueSnackbar } from 'notistack'
import Utils from 'Utils'
import { z } from 'zod'

import { AuthHeader } from 'Components/Blocks'

import * as paths from 'Constants/paths'

import { SendContactFormDocument } from 'GraphQL/Main/TypedDocuments'

enum Fields {
  Subject = 'subject',
  EventDate = 'eventDate',
  Message = 'message',
}

type Values = z.infer<typeof schema>

const schema = z.object({
  [Fields.Subject]: z
    .string()
    .min(1, { message: 'Subject is required' })
    .max(100, { message: 'Subject should be 100 characters at max' }),
  [Fields.EventDate]: z.custom<DateTime>(t => t instanceof DateTime).nullable(),
  [Fields.Message]: z
    .string()
    .min(1, { message: 'Message is required' })
    .max(1000, { message: 'Message should be 100 characters at max' }),
})

function ContactUsPage() {
  const [sendContactForm, { loading }] = useMutation(SendContactFormDocument)

  const history = useHistory()

  const { control, handleSubmit } = useForm<Values>({
    resolver: zodResolver(schema),
    defaultValues: {
      [Fields.Subject]: '',
      [Fields.EventDate]: null,
      [Fields.Message]: '',
    },
  })

  const onSubmit = useCallback(
    async (values: Values) => {
      try {
        await sendContactForm({
          variables: {
            subject: values[Fields.Subject],
            message: values[Fields.Message],
            eventDate: values[Fields.EventDate]?.toISODate() ?? '',
          },
        })

        history.replace(paths.CONTACT_US_SUCCESS)
      } catch (error) {
        const [graphQLError] = Utils.Errors.getGraphQLErrors(error)
        enqueueSnackbar(graphQLError, { variant: 'error' })
      }
    },
    [history, sendContactForm],
  )

  return (
    <Stack flexGrow={1}>
      <AuthHeader />

      <Stack px={4}>
        <Typography align="center" variant="h5">
          Get in Touch
        </Typography>

        <Typography align="center" color="text.secondary" mt={1.5}>
          Do you want to be inspired or have another question?
        </Typography>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack mt={4} spacing={6}>
            <Stack spacing={2.5}>
              <Controller
                control={control}
                name={Fields.Subject}
                render={({ field: { value, onChange }, fieldState }) => (
                  <TextField
                    autoCapitalize="none"
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    label="Subject"
                    value={value}
                    variant="standard"
                    onChange={onChange}
                  />
                )}
              />

              <Controller
                control={control}
                name={Fields.EventDate}
                render={({ field: { value, onChange } }) => (
                  <DatePicker
                    slotProps={{
                      textField: {
                        variant: 'standard',
                        helperText:
                          'If for a keynote, when will it take place?',
                      },
                    }}
                    value={value}
                    onChange={onChange}
                  />
                )}
              />

              <Controller
                control={control}
                name={Fields.Message}
                render={({ field: { value, onChange }, fieldState }) => (
                  <TextField
                    autoCapitalize="none"
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    label="Message"
                    multiline
                    rows={5}
                    value={value}
                    variant="standard"
                    onChange={onChange}
                  />
                )}
              />
            </Stack>

            <LoadingButton
              loading={loading}
              size="large"
              type="submit"
              variant="contained"
            >
              Submit
            </LoadingButton>
          </Stack>
        </form>
      </Stack>
    </Stack>
  )
}

export default ContactUsPage
