import { Components, Theme } from '@mui/material'

import { LinkBehavior } from 'Components/Blocks'

const MuiLink: Components<Theme>['MuiLink'] = {
  defaultProps: {
    component: LinkBehavior,
    underline: 'none',
  },
}

export default MuiLink
