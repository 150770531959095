import React from 'react'

import { Box } from '@mui/material'

import { Player } from './styles'

type Props = {
  kind?: 'episode' | 'show' | 'track'
  id: string
}

function SpotifyPlayer({ id, kind = 'episode' }: Props) {
  // const [aspectRatio, setAspectRatio] = useState(1)

  // const url = `https://open.spotify.com/${kind}/${id}`

  // useEffect(() => {
  //   async function fetchMetadata() {
  //     const response = await fetch(`https://open.spotify.com/oembed?url=${url}`)
  //     const metadata = await response.json()
  //     setAspectRatio(metadata.width / metadata.height)
  //   }

  //   fetchMetadata()
  // }, [url])

  // if (!aspectRatio) return null

  return (
    <Box position="relative" sx={{ width: '100%', height: 352 }}>
      <Player
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        loading="lazy"
        src={`https://open.spotify.com/embed/${kind}/${id}`}
        title="Spotify player"
      />
    </Box>
  )
}

export default SpotifyPlayer
