import React, { useCallback, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory } from 'react-router'

import { useMutation } from '@apollo/client'
import { zodResolver } from '@hookform/resolvers/zod'
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material'
import {
  Button,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { enqueueSnackbar } from 'notistack'
import Utils from 'Utils'
import { z } from 'zod'

import { AuthHeader } from 'Components/Blocks'

import * as paths from 'Constants/paths'
import { PASSWORD_REGEXP } from 'Constants/regexps'

import { SignInByEmailDocument } from 'GraphQL/Main/TypedDocuments'

import Auth from 'Services/Auth'

enum Fields {
  Email = 'email',
  Password = 'password',
}

type Values = z.infer<typeof schema>

const schema = z.object({
  [Fields.Email]: z.string().email('Must be a valid email'),
  [Fields.Password]: z
    .string()
    .min(1, { message: 'Password is required' })
    .min(8, { message: 'Password should have at least 8 characters' })
    .regex(PASSWORD_REGEXP, "Password requirements weren't met"),
})

function SignInPage() {
  const [showPassword, setShowPassword] = useState(false)
  const history = useHistory()

  const [signInByEmail] = useMutation(SignInByEmailDocument)

  const { control, handleSubmit } = useForm<Values>({
    resolver: zodResolver(schema),
    defaultValues: {
      [Fields.Email]: '',
      [Fields.Password]: '',
    },
  })

  const handlePasswordIconClick = useCallback(
    () => setShowPassword(show => !show),
    [],
  )

  const onSubmit = useCallback(
    async (values: Values) => {
      try {
        const response = await signInByEmail({
          variables: {
            email: values[Fields.Email],
            password: values[Fields.Password],
            withRefresh: true,
          },
        })

        await Auth.signIn({
          accessToken: response.data?.signInByEmail.accessToken,
          refreshToken: response.data?.signInByEmail.refreshToken,
        })

        history.push(paths.REDIRECTOR)
      } catch (error) {
        const [graphQLError] = Utils.Errors.getGraphQLErrors(error)
        enqueueSnackbar(graphQLError, { variant: 'error' })
      }
    },
    [history, signInByEmail],
  )

  return (
    <Stack flexGrow={1}>
      <AuthHeader />

      <Stack flexGrow={1} px={5}>
        <Typography align="center" variant="h5">
          Welcome back!
        </Typography>

        <Typography align="center" color="text.secondary" mt={1.5}>
          Your on-demand strategic futurist and keynote speaker
        </Typography>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack mt={4} spacing={5}>
            <Stack spacing={2.5}>
              <Controller
                control={control}
                name={Fields.Email}
                render={({ field: { value, onChange }, fieldState }) => (
                  <TextField
                    autoCapitalize="none"
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    inputProps={{
                      type: 'email',
                    }}
                    label="Email"
                    value={value}
                    variant="standard"
                    onChange={onChange}
                  />
                )}
              />

              <Controller
                control={control}
                name={Fields.Password}
                render={({ field: { value, onChange }, fieldState }) => (
                  <TextField
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            onClick={handlePasswordIconClick}
                          >
                            {showPassword ? (
                              <VisibilityOffOutlined />
                            ) : (
                              <VisibilityOutlined />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    autoComplete="true"
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    label="Password"
                    type={showPassword ? 'text' : 'password'}
                    value={value}
                    variant="standard"
                    onChange={onChange}
                  />
                )}
              />
            </Stack>

            <Button size="large" type="submit" variant="contained">
              Sign in
            </Button>
          </Stack>
        </form>
      </Stack>

      <Stack pb={2} pt={1} px={5}>
        <Button href={paths.REQUEST_RESET_PASSWORD} size="large">
          Forgot password?
        </Button>
      </Stack>
    </Stack>
  )
}

export default SignInPage
